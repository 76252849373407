import { gql } from "@apollo/client";

export const GET_TRENDS = gql`
  query getTrends(      
    $metric: Metric!
    $groupBy: Grouping!
    $stateProvinceNames: [String]
    $counties: [String]
    $yearBuiltGroups: [String]
    $gfaGroups: [String]
    $gfaGroup2s: [String]
    $csa_city: [String]
    $csa_area: [String]
    $climateZone: [String]
    $isEnergyStarCertified: Boolean
    $workerDensityGroups: [String]
    $weeklyHoursGroup: [String]
    $ptCategories: [String]
    $ptSubcategories: [String]
    $withMeanTrend: Boolean!
    $withMedianTrend: Boolean!
    $withFifthPercentileTrend: Boolean!
    $withTwentyFifthPercentileTrend: Boolean!
    $withSeventyFifthPercentileTrend: Boolean!
    $withNinetyFifthPercentileTrend: Boolean!
    $trendStartYear:Int!
    $trendEndYear:Int!) {
    getTrends(
      metric: $metric
      groupBy: $groupBy
      stateProvinceNames: $stateProvinceNames
      counties: $counties
      yearBuiltGroups: $yearBuiltGroups
      gfaGroups: $gfaGroups
      gfaGroup2s: $gfaGroup2s
      csa_city: $csa_city
      csa_area: $csa_area
      climateZone: $climateZone
      isEnergyStarCertified: $isEnergyStarCertified
      workerDensityGroups: $workerDensityGroups
      weeklyHoursGroup: $weeklyHoursGroup
      ptCategories: $ptCategories
      ptSubcategories: $ptSubcategories
      withMean: $withMeanTrend
      withMedian: $withMedianTrend
      withFifthPercentile : $withFifthPercentileTrend
      withTwentyFifthPercentile: $withTwentyFifthPercentileTrend
      withSeventyFifthPercentile: $withSeventyFifthPercentileTrend
      withNinetyFifthPercentile: $withNinetyFifthPercentileTrend
      trendStartYear: $trendStartYear
      trendEndYear: $trendEndYear
     ) {
      count
      results {
        group
        yearreported
        median   @include(if: $withMedianTrend)
        mean  @include(if: $withMeanTrend)
        fifthpercentile @include(if: $withFifthPercentileTrend)
        twentyfifthpercentile @include(if: $withTwentyFifthPercentileTrend)
        seventyfifthpercentile  @include(if: $withSeventyFifthPercentileTrend)
        ninetyfifthpercentile @include(if: $withNinetyFifthPercentileTrend)
      }
    }
  }
  `;