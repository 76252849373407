export const energyUseIntensity = [
  {
    value: "Site EUI",
    id: "energy-use-site-eui",
    type: "EUI",
    unit: "(kBtu/sq. ft.)",
    defaultChecked: true,
    name: "siteEui",
  },
  {
    value: "Source EUI",
    id: "energy-use-source-eui",
    type: "EUI",
    unit: "(kBtu/sq. ft.)",
    defaultChecked: false,
    name: "sourceEui",
  },
  {
    value: "Weather Normalized Site EUI",
    id: "weather-site-eui",
    type: "EUI",
    unit: "(kBtu/sq. ft.)",
    defaultChecked: false,
    name: "weatherNormalizedSiteEui",
  },
  {
    value: "Weather Normalized Source EUI",
    id: "weather-source-eui",
    type: "EUI",
    unit: "(kBtu/sq. ft.)",
    defaultChecked: false,
    name: "weatherNormalizedSourceEui",
  },
  {
    value: "ENERGY STAR Score",
    valueR: "ENERGY STAR® Score",
    id: "energy-star-score",
    type: "other",
    unit: "",
    defaultChecked: false,
    name: "energyStarScore",
    glossaryLink:
      "https://portfoliomanager.energystar.gov/pm/glossary#EnergyStarScore",
  },
  {
    value: "Percent Electricity",
    id: "percent-electricity",
    type: "other",
    unit: "(%)",
    defaultChecked: false,
    name: "percentElectricity",
    glossaryLink:
      "https://portfoliomanager.energystar.gov/pm/glossary#PercentElectricity",
  },
  {
    value: "Water Use Intensity (gal/sq. ft.)",
    id: "water-use-intensity",
    type: "other",
    unit: "",
    defaultChecked: false,
    name: "water_use_intensity",
    glossaryLink:
      "https://portfoliomanager.energystar.gov/pm/glossary#WaterUse",
  },
  {
    value: "Water Score (Multi-family Only)",
    id: "water-score",
    type: "other",
    unit: "",
    defaultChecked: false,
    name: "water_score",
    glossaryLink:
      "https://portfoliomanager.energystar.gov/pm/glossary#WaterScore",
  },
];

export const tableColumns = [
  {
    value: "5th Percentile",
    id: "check-table-columns-5",
    defaultChecked: false,
  },
  {
    value: "25th Percentile",
    id: "check-table-columns-25",
    defaultChecked: true,
  },
  {
    value: "Mean",
    id: "check-table-columns-mean",
    defaultChecked: false,
  },
  {
    value: "Median",
    id: "check-table-columns-median",
    defaultChecked: true,
  },
  {
    value: "75th Percentile",
    id: "check-table-columns-75",
    defaultChecked: true,
  },
  {
    value: "95th Percentile",
    id: "check-table-columns-95",
    defaultChecked: false,
  },
];

export const trendStatisticsMapping: Record<
string,
| "SET_WITH_MEAN_TREND"
| "SET_WITH_MEDIAN_TREND"
| "SET_WITH_FIFTH_PERCENTILE_TREND"
| "SET_WITH_TWENTY_FIFTH_PERCENTILE_TREND"
| "SET_WITH_SEVENTY_FIFTH_PERCENTILE_TREND"
| "SET_WITH_NINETY_FIFTH_PERCENTILE_TREND"
> = {
Mean: "SET_WITH_MEAN_TREND",
Median: "SET_WITH_MEDIAN_TREND",
"5th Percentile": "SET_WITH_FIFTH_PERCENTILE_TREND",
"25th Percentile": "SET_WITH_TWENTY_FIFTH_PERCENTILE_TREND",
"75th Percentile": "SET_WITH_SEVENTY_FIFTH_PERCENTILE_TREND",
"95th Percentile": "SET_WITH_NINETY_FIFTH_PERCENTILE_TREND",
};

export const trendStatisticsRadios = [
  {
    value: "5th Percentile",
    id: "check-trend-statistic-5",
  },
  {
    value: "25th Percentile",
    id: "check-trend-statistic-25",
  },
  {
    value: "Mean",
    id: "check-trend-statistic-mean",
  },
  {
    value: "Median",
    id: "check-trend-statistic-median",
  },
  {
    value: "75th Percentile",
    id: "check-trend-statistic-75",
  },
  {
    value: "95th Percentile",
    id: "check-trend-statistic-95",
  },
];

// Data Years available should match API .env SEED_YEAR_MIN/MAX values
export const yearReportedRadios = [
  {
    value: 2023,
    id: "year-reported-2023",
    defaultChecked: true,
  },
  {
    value: 2022,
    id: "year-reported-2022",
    defaultChecked: false,
  },
  {
    value: 2021,
    id: "year-reported-2021",
    defaultChecked: false,
  },
  {
    value: 2020,
    id: "year-reported-2020",
    defaultChecked: false,
  },
  {
    value: 2019,
    id: "year-reported-2019",
    defaultChecked: false,
  },
  {
    value: 2018,
    id: "year-reported-2018",
    defaultChecked: false,
  },
];

export const chartOptions = [
  {
    value: "Bar Charts",
    id: "chart-options-cbc",
    defaultChecked: true,
    tooltip: "View medians for filtered data represented by bars",
  },
  {
    value: "Box-and-Whisker Plots",
    id: "chart-options-bwp",
    defaultChecked: false,
    tooltip:
      "View percentiles, median, and mean for filtered data in a graphical format",
  },
  {
    value: "Trends",
    id: "chart-options-trends",
    defaultChecked: false,
    tooltip: "View trends in the data over time",
  }
];

export const energyStarCertified = [
  {
    value: "Yes",
    id: "energy-star-certified-yes",
    defaultChecked: false,
    isCertified: true,
  },
  {
    value: "No",
    id: "energy-star-certified-no",
    defaultChecked: false,
    isCertified: false,
  },
  {
    value: "Show All",
    id: "energy-star-certified-all",
    defaultChecked: true,
    isCertified: undefined,
  },
];

export const yearBuilt = [
  {
    value: "Before 1946",
    id: "year-build-before-1946",
    label: "Before 1946",
    defaultChecked: false,
  },
  {
    value: "1946-1959",
    id: "year-build-1946-1959",
    label: "1946-1959",
    defaultChecked: false,
  },
  {
    value: "1960-1979",
    id: "year-build-1960-1979",
    label: "1960-1979",
    defaultChecked: false,
  },
  {
    value: "1980-1999",
    id: "year-build-1980-1999",
    label: "1980-1999",
    defaultChecked: false,
  },
  {
    value: "2000-2009",
    id: "year-build-2000-2009",
    label: "2000-2009",
    defaultChecked: false,
  },
  {
    value: "2010 and after",
    id: "year-build-after-2010",
    label: "2010 and after",
    defaultChecked: false,
  },
];

export const grossFloorArea = [
  {
    label: "1,000–24,999",
    value: "1,000 - 24,999",

    children: [
      {
        label: "1,000–4,999",
        value: "1,000 - 4,999",
      },
      {
        label: "5,000–9,999",
        value: "5,000 - 9,999",
      },
      {
        label: "10,000–24,999",
        value: "10,000 - 24,999",
      },
    ],
  },
  {
    label: "25,000–499,999",
    value: "25,000 - 499,999",

    children: [
      {
        label: "25,000–49,999",
        value: "25,000 - 49,999",
      },
      {
        label: "50,000–99,999",
        value: "50,000 - 99,999",
      },
      {
        label: "100,000–199,999",
        value: "100,000 - 199,999",
      },
      {
        label: "200,000–499,999",
        value: "200,000 - 499,999",
      },
    ],
  },
  {
    label: "500,000+",
    value: "500,000+",

    children: [
      {
        label: "500,000–999,999",
        value: "500,000 - 999,999",
      },
      {
        label: "1,000,000+",
        value: "1,000,000+",
      },
    ],
  },
];

export const displayByFilters = [
  {
    name: "Property Type",
    value: "ptCategory",
  },
  {
    name: "Property Type Subcategory",
    value: "ptSubcategory",
  },
  {
    name: "Gross Floor Area",
    value: "gfaGroup",
  },
  {
    name: "State",
    value: "stateProvinceName",
  },
  {
    name: "Climate Zone",
    value: "climateZone",
  },
  {
    name: "CBSA",
    value: "csa_city",
  },
  {
    name: "Year Built",
    value: "yearBuiltGroup",
  },
];

export const thenByFilters = [
  {
    name: "Property Type",
    value: "ptCategory",
  },
  {
    name: "Property Type Subcategory",
    value: "ptSubcategory",
  },
  {
    name: "Gross Floor Area",
    value: "gfaGroup",
  },
  {
    name: "State",
    value: "stateProvinceName",
  },
  {
    name: "Climate Zone",
    value: "climateZone",
  },
  {
    name: "Year Built",
    value: "yearBuiltGroup",
  },
];

export const stateFilters = [
  {
    label: "Alabama",
    value: "Alabama",
    checked: false,
  },
  {
    label: "Alaska",
    value: "Alaska",
    checked: false,
  },
  {
    label: "American Samoa",
    value: "American Samoa",
    checked: false,
  },
  {
    label: "Arizona",
    value: "Arizona",
    checked: false,
  },
  {
    label: "Arkansas",
    value: "Arkansas",
    checked: false,
  },
  {
    label: "California",
    value: "California",
    checked: false,
  },
  {
    label: "Colorado",
    value: "Colorado",
    checked: false,
  },
  {
    label: "Connecticut",
    value: "Connecticut",
    checked: false,
  },
  {
    label: "Delaware",
    value: "Delaware",
    checked: false,
  },
  {
    label: "Florida",
    value: "Florida",
    checked: false,
  },
  {
    label: "Georgia",
    value: "Georgia",
    checked: false,
  },
  {
    label: "Guam",
    value: "Guam",
    checked: false,
  },
  {
    label: "Hawaii",
    value: "Hawaii",
    checked: false,
  },
  {
    label: "Idaho",
    value: "Idaho",
    checked: false,
  },
  {
    label: "Illinois",
    value: "Illinois",
    checked: false,
  },
  {
    label: "Indiana",
    value: "Indiana",
    checked: false,
  },
  {
    label: "Iowa",
    value: "Iowa",
    checked: false,
  },
  {
    label: "Kansas",
    value: "Kansas",
    checked: false,
  },
  {
    label: "Kentucky",
    value: "Kentucky",
    checked: false,
  },
  {
    label: "Louisiana",
    value: "Louisiana",
    checked: false,
  },
  {
    label: "Maine",
    value: "Maine",
    checked: false,
  },
  {
    label: "Maryland",
    value: "Maryland",
    checked: false,
  },
  {
    label: "Massachusetts",
    value: "Massachusetts",
    checked: false,
  },
  {
    label: "Michigan",
    value: "Michigan",
    checked: false,
  },
  {
    label: "Minnesota",
    value: "Minnesota",
    checked: false,
  },
  {
    label: "Mississippi",
    value: "Mississippi",
    checked: false,
  },
  {
    label: "Missouri",
    value: "Missouri",
    checked: false,
  },
  {
    label: "Montana",
    value: "Montana",
    checked: false,
  },
  {
    label: "Nebraska",
    value: "Nebraska",
    checked: false,
  },
  {
    label: "Nevada",
    value: "Nevada",
    checked: false,
  },
  {
    label: "New Hampshire",
    value: "New Hampshire",
    checked: false,
  },
  {
    label: "New Jersey",
    value: "New Jersey",
    checked: false,
  },
  {
    label: "New Mexico",
    value: "New Mexico",
    checked: false,
  },
  {
    label: "New York",
    value: "New York",
    checked: false,
  },
  {
    label: "North Carolina",
    value: "North Carolina",
    checked: false,
  },
  {
    label: "North Dakota",
    value: "North Dakota",
    checked: false,
  },
  {
    label: "Northern Mariana Islands",
    value: "Northern Mariana Islands",
    checked: false,
  },
  {
    label: "Ohio",
    value: "Ohio",
    checked: false,
  },
  {
    label: "Oklahoma",
    value: "Oklahoma",
    checked: false,
  },
  {
    label: "Oregon",
    value: "Oregon",
    checked: false,
  },
  {
    label: "Pennsylvania",
    value: "Pennsylvania",
    checked: false,
  },
  {
    label: "Puerto Rico",
    value: "Puerto Rico",
    checked: false,
  },
  {
    label: "Rhode Island",
    value: "Rhode Island",
    checked: false,
  },
  {
    label: "South Carolina",
    value: "South Carolina",
    checked: false,
  },
  {
    label: "South Dakota",
    value: "South Dakota",
    checked: false,
  },
  {
    label: "Tennessee",
    value: "Tennessee",
    checked: false,
  },
  {
    label: "Texas",
    value: "Texas",
    checked: false,
  },
  {
    label: "Utah",
    value: "Utah",
    checked: false,
  },
  {
    label: "U.S. Virgin Islands",
    value: "U.S. Virgin Islands",
    checked: false,
  },
  {
    label: "Vermont",
    value: "Vermont",
    checked: false,
  },
  {
    label: "Virginia",
    value: "Virginia",
    checked: false,
  },
  {
    label: "Washington",
    value: "Washington",
    checked: false,
  },
  {
    label: "Washington, DC",
    value: "Washington, DC",
    checked: false,
  },
  {
    label: "West Virginia",
    value: "West Virginia",
    checked: false,
  },
  {
    label: "Wisconsin",
    value: "Wisconsin",
    checked: false,
  },
  {
    label: "Wyoming",
    value: "Wyoming",
    checked: false,
  },
];

export const countyFilters = [
  {
    label: "Alabama",
    value: "Alabama",
    checked: false,

    children: [
      {
        label: "County 1",
        value: "Alabama_County1",
      },
      {
        label: "County 2",
        value: "Alabama_County2",
      },
      {
        label: "County 3",
        value: "Alabama_County3",
      },
    ],
  },
  {
    label: "Alaska",
    value: "Alaska",
    checked: false,

    children: [
      {
        label: "County 1",
        value: "Alaska_County1",
      },
      {
        label: "County 2",
        value: "Alaska_County2",
      },
      {
        label: "County 3",
        value: "Alaska_County3",
      },
    ],
  },
  {
    label: "Arkansas",
    value: "Arkansas",
    checked: false,

    children: [
      {
        label: "County 1",
        value: "Arkansas_County1",
      },
      {
        label: "County 2",
        value: "Arkansas_County2",
      },
      {
        label: "County 3",
        value: "Arkansas_County3",
      },
    ],
  },
  {
    label: "California",
    value: "California",
    checked: false,

    children: [
      {
        label: "County 1",
        value: "California_County1",
      },
      {
        label: "County 2",
        value: "California_County2",
      },
      {
        label: "County 3",
        value: "California_County3",
      },
    ],
  },
  {
    label: "Colorado",
    value: "Colorado",
    checked: false,

    children: [
      {
        label: "Denver",
        value: "Colorado_Denver",
      },
      {
        label: "Mesa",
        value: "Colorado_Mesa",
      },
      {
        label: "Broomfield",
        value: "Colorado_Broomfield",
      },
      {
        label: "Arapahoe",
        value: "Colorado_Arapahoe",
      },
      {
        label: "Adams",
        value: "Colorado_Adams",
      },
      {
        label: "Washington",
        value: "Colorado_Washington",
      },
    ],
  },
  {
    label: "Connecticut",
    value: "Connecticut",
    checked: false,

    children: [
      {
        label: "County 1",
        value: "Connecticut_County1",
      },
      {
        label: "County 2",
        value: "Connecticut_County2",
      },
      {
        label: "County 3",
        value: "Connecticut_County3",
      },
    ],
  },
  {
    label: "Texas",
    value: "Texas",
    checked: false,

    children: [
      {
        label: "Mesa",
        value: "Texas_Mesa",
      },
    ],
  },
  {
    label: "Iowa",
    value: "Iowa",
    checked: false,

    children: [
      {
        label: "Newt",
        value: "Iowa_Newt",
      },
      {
        label: "Mesa",
        value: "Iowa_Mesa",
      },
    ],
  },
];

export const workerDensity = [
  {
    value: "Less than 0.2",
    id: "workerDensity-Less-than-0x2",
    label: "Less than 0.2",
    defaultChecked: false,
  },
  {
    value: "0.2 - 0.5",
    id: "workerDensity-0x2-0x5",
    label: "0.2 - 0.5",
    defaultChecked: false,
  },
  {
    value: "0.51 - 1",
    id: "workerDensity-gt0x5-1",
    label: "0.51 - 1",
    defaultChecked: false,
  },
  {
    value: "1.01 - 2",
    id: "workerDensity-gt1-2",
    label: "1.01 - 2",
    defaultChecked: false,
  },
  {
    value: "2.01 - 3",
    id: "workerDensity-gt2-3",
    label: "2.01 - 3",
    defaultChecked: false,
  },
  {
    value: "Over 3",
    id: "workerDensity-Over-3",
    label: "Over 3",
    defaultChecked: false,
  },
];

export const weeklyHours = [
  {
    value: "Fewer than 40",
    id: "wklyHrs-Fewer-than-40",
    label: "Fewer than 40",
    defaultChecked: false,
  },
  {
    value: "40-48",
    id: "wklyHrs-40-48",
    label: "40 - 48",
    defaultChecked: false,
  },
  {
    value: "48.01 - 60",
    id: "wklyHrs-gt48-60",
    label: "48.01 - 60",
    defaultChecked: false,
  },
  {
    value: "60.01 - 84",
    id: "wklyHrs-gt60-84",
    label: "60.01 - 84",
    defaultChecked: false,
  },
  {
    value: "84.01-167",
    id: "wklyHrs-gt84-167",
    label: "84.01 - 167",
    defaultChecked: false,
  },
  {
    value: "Open Continuously",
    id: "wklyHrs-Open-Continuously",
    label: "Open Continuously",
    defaultChecked: false,
  },
  {
    value: "Data not available/collected",
    id: "wklyHrs-Data-not-available_collected",
    label: "Data not available/collected",
    defaultChecked: false,
  },
];
