import React, { FC, useEffect, useState } from "react";
import Table from "../table/table";
import styles from "./mainContent.module.scss";
import {
  thenByFilters,
  energyUseIntensity,
  displayByFilters,
} from "../../assets/data/filters";
import { CircularProgress } from "@mui/material";
import Charts from "../charts/charts";
import TrendChart from "../trendChart/trendChart";
import {
  useAppContext,
  useDispatchContext,
  useFiltersQuery,
  useResultsQuery,
  useTrendsQuery,
} from "../../AppProvider";
import {
  GetFiltersQuery,
  GetResultsQuery,
  GetTrendsQuery,
} from "../../graphql/generated/graphql";

interface MainContentProps {
  isMobile: any;
  setReadableThenBy: any;
  readableThenBy: any;
  readableGroupBy: any;
  groupByResults: any;
  thenByResults: any;
  setGroupByResults: any;
  setThenByResults: any;
  chartType: any;
  view: any;
  theadData: any;
  processedAllRow: any;
  setProcessedAllRow: any;
  entries: any;
  setEntries: any;
  setSortedChartData: any;
  sortBy: any;
  setSortBy: any;
}

const MainContent: FC<MainContentProps> = ({
  isMobile,
  setReadableThenBy,
  readableThenBy,
  readableGroupBy,
  groupByResults,
  thenByResults,
  setGroupByResults,
  setThenByResults,
  chartType,
  view,
  theadData,
  processedAllRow,
  setProcessedAllRow,
  entries,
  setEntries,
  setSortedChartData,
  sortBy,
  setSortBy,
}) => {
  const {
    isTrendsShown,
    trendEndYear,
    trendStartYear,
    withMeanTrend,
    withMedianTrend,
    withFifthPercentileTrend,
    withTwentyFifthPercentileTrend,
    withSeventyFifthPercentileTrend,
    withNinetyFifthPercentileTrend,
    metric,
    isEnergyStarCertified,
    yearReported,
    yearBuiltGroups,
    stateProvinceNames,
    gfaGroups,
    gfaGroup2s,
    weeklyHoursGroup,
    ptCategories,
    ptSubcategories,
    csa_area,
    csa_city,
    climateZone,
    groupBy,
    thenBy,
    location,
  } = useAppContext();

  const dispatch = useDispatchContext();
  const { data: trendsData, loading: trendsLoading } =
    useTrendsQuery<GetTrendsQuery>();
  const { loading: isFilterPanelLoading } = useFiltersQuery<GetFiltersQuery>();
  const { data: results, loading } = useResultsQuery<GetResultsQuery>();
  const count = results?.getResults?.count;

  const [max, setMax] = useState<number>(0);
  const [min, setMin] = useState<number>(0);

  const selectedTrend = withMeanTrend
    ? "Mean"
    : withMedianTrend
    ? "Median"
    : withFifthPercentileTrend
    ? "5th Percentile"
    : withTwentyFifthPercentileTrend
    ? "25th Percentile"
    : withSeventyFifthPercentileTrend
    ? "75th Percentile"
    : withNinetyFifthPercentileTrend
    ? "95th Percentile"
    : "Median";

  const generateCaption = () => {
    const filters = [];

    // Data Year
    if (yearReported && !isTrendsShown) {
      filters.push(`Data Year: ${yearReported}`);
    }

    if (trendEndYear && trendStartYear && isTrendsShown) {
      filters.push(`Data Years: ${trendStartYear}–${trendEndYear}`);
    }

    if (selectedTrend && isTrendsShown) {
      filters.push(`Trend Statistic: ${selectedTrend}`);
    }

    // Property Types
    if (
      (ptCategories && ptCategories.length) ||
      (ptSubcategories && ptSubcategories.length)
    ) {
      filters.push(
        `Property Types: ${[
          ...(ptCategories || []),
          ...(ptSubcategories || []),
        ].join(", ")}`
      );
    } else {
      filters.push("Property Types: All");
    }

    // Gross Floor Area
    if ((gfaGroup2s && gfaGroup2s.length) || (gfaGroups && gfaGroups.length)) {
      filters.push(
        `Gross Floor Area: ${[...(gfaGroup2s || []), ...(gfaGroups || [])].join(
          ", "
        )}`
      );
    } else {
      filters.push("Gross Floor Area: All");
    }

    // Location (State, CBSA, Climate Zone)
    if (location === "state") {
      if (stateProvinceNames && stateProvinceNames.length) {
        filters.push(
          `States: ${
            stateProvinceNames.length <= 5
              ? stateProvinceNames.join(", ")
              : `${stateProvinceNames.length} states`
          }`
        );
      } else {
        filters.push("States: All");
      }
    } else if (location === "csa") {
      if ((csa_area && csa_area.length) || (csa_city && csa_city.length)) {
        filters.push(
          `CBSAs: ${[...(csa_area || []), ...(csa_city || [])].join(", ")}`
        );
      } else {
        filters.push("CBSAs: All");
      }
    } else if (location === "climate") {
      if (climateZone && climateZone.length) {
        filters.push(
          `Climate Zones: ${
            climateZone.length <= 5
              ? climateZone.join(", ")
              : `${climateZone.length} climate zones`
          }`
        );
      } else {
        filters.push("Climate Zones: All");
      }
    } else {
      filters.push("Location: All");
    }

    // ENERGY STAR Certified
    filters.push(
      `ENERGY STAR Certified: ${
        isEnergyStarCertified === true
          ? "Yes"
          : isEnergyStarCertified === false
          ? "No"
          : "Show All"
      }`
    );

    // Year Built
    if (yearBuiltGroups && yearBuiltGroups.length) {
      filters.push(`Years Built: ${yearBuiltGroups.join(", ")}`);
    } else {
      filters.push("Years Built: All");
    }

    // Weekly Operating Hours
    if (weeklyHoursGroup && weeklyHoursGroup.length) {
      filters.push(`Weekly Operating Hours: ${weeklyHoursGroup.join(", ")}`);
    } else {
      filters.push("Weekly Operating Hours: All");
    }

    return `Applied filters: ${filters.join("; ")}`;
  };

  // Captions that appera in chart download only

  const caption = generateCaption();

  const generateChartCount = () => {
    let downloadableCount = isTrendsShown
      ? trendsData?.getTrends?.count
      : count?.toLocaleString();

    return `Results based on ${downloadableCount} properties`;
  };

  const chartCount = generateChartCount();

  const generateChartTitle = () => {
    return `${chartType === "Bar Charts" ? "Median " : isTrendsShown ? selectedTrend : ""}
    ${
      metric === "energyStarScore"
        ? energyUseIntensity.find((x) => x.name === metric)?.valueR
        : energyUseIntensity.find((x) => x.name === metric)?.value
    } ${" "}
    ${energyUseIntensity.find((x) => x.name === metric)?.unit}`;
  };

  const chartTitle = generateChartTitle();

  // TODO: Move this to charts remove min and max state
  useEffect(() => {
    let data: any = [];
    if (metric === "energyStarScore" || metric === "percentElectricity") {
      if (results && results.getResults) {
        setMax(100);
      }
    } else {
      if (results && results.getResults) {
        if (chartType === "Box-and-Whisker Plots") {
          results.getResults.results.map((d: any) => {
            return data.push(d.ninetyFifthPercentile);
          });
        } else {
          results.getResults.results.map((d: any) => {
            return data.push(d.median);
          });
        }
        let max = Math.round(Math.max(...data));
        setMax(max + 1);
      }
    }
    setMin(0);
  }, [results, chartType, groupBy, thenBy, metric, view]);

  let handleThenBy = (e: any) => {
    const payload =
      e.target.value === "(Select an option)" ? undefined : e.target.value;
    dispatch({ type: "SET_THEN_BY", payload: payload });
    setReadableThenBy(thenByFilters.find((x) => x.value === payload)?.name);
  };

  let handleGroupBy = (e: any) => {
    dispatch({ type: "SET_GROUP_BY", payload: e.target.value });
  };

  return (
    <div className={styles.MainContent} data-testid="MainContent">
      <div className={`${styles.MainContentOverflow} grid-row`}>
        <div
          className={`${styles.results} tablet:grid-col-9 tablet:padding-right-205`}
        >
          <div className={styles.resultsTitle}>
            <h2>
              {chartType === "Bar Charts" ? "Median " : ""}
              {isTrendsShown ? `${selectedTrend} ` : ""}
              {metric === "energyStarScore"
                ? energyUseIntensity.find((x) => x.name === metric)?.valueR
                : energyUseIntensity.find((x) => x.name === metric)?.value}{" "}
              {energyUseIntensity.find((x) => x.name === metric)?.unit}
            </h2>
            {loading || isFilterPanelLoading ? (
              ""
            ) : !isTrendsShown ? (
              <p>
                Results based on {count?.toLocaleString()} properties
              </p>
            ) : ""}
          </div>
          <div className={`${styles.displayDropdownsWrapper} grid-row`}>
            <div className={`${styles.dropdown} step--display-by grid-col`}>
              <label
                className="usa-label"
                htmlFor="groupBy"
                id="group-by-label"
              >
                {view === "Table"
                  ? "Display first by"
                  : chartType === "Trends"
                  ? "Display by"
                  : "Y-Axis"}
              </label>
              <select
                onChange={(e) => handleGroupBy(e)}
                value={groupBy}
                className="usa-select"
                name="groupBy"
                id="groupBy"
              >
                {displayByFilters.map((object) => (
                  <option
                    key={object.name}
                    value={object.value}
                    disabled={
                      object.value === thenBy ||
                      (object.value === "ptSubcategory" &&
                        thenBy === "ptCategory")
                        ? true
                        : false
                    }
                  >
                    {object.name}
                  </option>
                ))}
              </select>
            </div>
            {view === "Table" ? (
              <div className={`${styles.dropdown} grid-col`}>
                <label
                  className={
                    groupBy === "csa_city" ? "disabled-label" : "usa-label"
                  }
                  htmlFor="thenBy"
                  id="then-by-label"
                >
                  Then by (optional)
                </label>
                <select
                  onChange={(e) => handleThenBy(e)}
                  value={groupBy === "csa_city" ? "" : thenBy}
                  className="usa-select"
                  name="thenBy"
                  id="thenBy"
                  disabled={groupBy === "csa_city" ? true : false}
                  aria-describedby="cbsa-hint"
                >
                  <option key="Select an option">(Select an option)</option>
                  {thenByFilters.map((object) => (
                    <option
                      key={object.name}
                      value={object.value}
                      disabled={
                        object.value === groupBy ||
                        (object.value === "ptCategory" &&
                          groupBy === "ptSubcategory")
                          ? true
                          : false
                      }
                    >
                      {object.name}
                    </option>
                  ))}
                </select>
                {groupBy === "csa_city" ? (
                  <p className="usa-hint mt-0" id="cbsa-hint">
                    <small>Then by display is not available for CBSAs</small>
                  </p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {/* placeholder div */}
            <div className="grid-col"></div>
          </div>

          <p className="EUI-disclaimer m-halfRem">
            <b>
              Note:{" "}
              {isTrendsShown ? "Data points for years in which a category had 5 or fewer properties matching your filters have been omitted. Categories with zero matching properties do not appear in the chart." : view === "Table"
                ? "Dashes indicate categories with 5 or fewer data points. Categories with zero data points have been omitted."
                : "The" +
                  (chartType === "Bar Charts"
                    ? " bars "
                    : chartType === "Box-and-Whisker Plots"
                    ? " boxes "
                    : " paths ") +
                  "for categories with 5 or fewer data points have been omitted. Categories with zero data points do not appear in the chart."}
            </b>
          </p>

          {!isTrendsShown && results && results.getResults ? (
            results.getResults.count && count !== "≤5" ? (
              view === "Table" ? (
                <Table
                  groupByResults={groupByResults}
                  thenByResults={thenByResults}
                  setGroupByResults={setGroupByResults}
                  setThenByResults={setThenByResults}
                  metric={metric}
                  readableGroupBy={readableGroupBy}
                  readableThenBy={readableThenBy}
                  groupBy={groupBy}
                  thenBy={thenBy}
                  results={results}
                  theadData={theadData}
                  processedAllRow={processedAllRow}
                  setProcessedAllRow={setProcessedAllRow}
                  entries={entries}
                  setEntries={setEntries}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                />
              ) : view === "Map" ? (
                <p>map</p>
              ) : view === "Chart" ? (
                <Charts
                  isMobile={isMobile}
                  min={min}
                  max={max}
                  readableGroupBy={readableGroupBy}
                  metric={metric}
                  groupBy={groupBy}
                  results={results}
                  chartType={chartType}
                  setSortedChartData={setSortedChartData}
                  caption={caption}
                  chartCount={chartCount}
                  chartTitle={chartTitle}
                />
              ) : (
                <p>Make a selection</p>
              )
            ) : results.getResults.count && count === "≤5" ? (
              <div className={styles.errorWrapper}>
                {loading || isFilterPanelLoading ? (
                  ""
                ) : (
                  <div id="no-matching-results-error-msg">
                    {" "}
                    <h3>Not enough results</h3>
                    <p>Try changing your search</p>
                  </div>
                )}
              </div>
            ) : loading || isFilterPanelLoading ? (
              <div className="loading-container">
                <div className="loading-container-inner">
                  <p>Loading your data...</p>
                  <CircularProgress />
                </div>
              </div>
            ) : (
              <div className={styles.errorWrapper}>
                {loading || isFilterPanelLoading ? (
                  ""
                ) : (
                  <div id="no-matching-results-error-msg">
                    {" "}
                    <h3>No matching results</h3>
                    <p>Try changing your search</p>
                  </div>
                )}
              </div>
            )
          ) : loading || isFilterPanelLoading ? (
            <div className="loading-container">
              <div className="loading-container-inner">
                <p>Loading your data...</p>
                <CircularProgress />
              </div>
            </div>
          ) : (
            ""
          )}
          {isTrendsShown ? (
            trendsLoading ? (
              <div className="loading-container">
                <div className="loading-container-inner">
                  <p>Loading your data...</p>
                  <CircularProgress />
                </div>
              </div>
            ) : !trendsData ||
              (trendsData.getTrends && trendsData.getTrends.results[0] === null) ? (
              <div className={styles.errorWrapper}>
                <div id="no-matching-results-error-msg">
                  <h3>Not enough results</h3>
                  <p>Try changing your search</p>
                </div>
              </div>
            ) : (
              <TrendChart
                chartCount={chartCount}
                chartTitle={chartTitle}
                caption={caption}
                groupBy={groupBy}
              />
            )
          ) : null}
        </div>
        <div className={`${styles.details} step--details-panel`}>
          <h2>Applied Filters</h2>
          <button
            type="button"
            className="usa-button usa-button--active resetButton"
            id="reset-to-defaults"
            onClick={() => dispatch({ type: "RESET_FILTERS" })}
          >
            Reset all Filters
          </button>
          <div className={`${styles.filters} grid-col`}>
            {!isTrendsShown && (
              <>
                <label
                  className={styles.filtersLabel}
                  id="year-reported-filters-label"
                >
                  Data Year
                </label>
                <div
                  className={styles.filtersContainer}
                  aria-labelledby="year-reported-filters-label"
                  id="year-reported-filters"
                >
                  <span className="usa-tag">{yearReported}</span>
                </div>
              </>
            )}

            {isTrendsShown && (
              <>
                <label
                  className={styles.filtersLabel}
                  id="trend-statistics-filters-label"
                >
                  Trend Statistic
                </label>
                <div
                  className={styles.filtersContainer}
                  aria-labelledby="trend-statistics-filters-label"
                  id="trend-statistics-filters"
                >
                  <span className="usa-tag">{selectedTrend}</span>
                </div>
                <label
                  className={styles.filtersLabel}
                  id="trend-start-year-filters-label"
                >
                  Data Years
                </label>
                <div
                  className={styles.filtersContainer}
                  aria-labelledby="trend-year-range-filters-label"
                  id="trend-year-range-filters"
                >
                  <span className="usa-tag" style={{ marginRight: 0 }}>
                    {trendStartYear}
                  </span>
                  <span> – </span>
                  <span className="usa-tag">{trendEndYear}</span>
                </div>
              </>
            )}

            <label
              className={styles.filtersLabel}
              id="property-type-filters-label"
            >
              Property Types
            </label>
            {(ptCategories && ptCategories.length >= 1) ||
            (ptSubcategories && ptSubcategories.length >= 1) ? (
              <div>
                <div
                  className={styles.filtersContainer}
                  id="property-type-filters"
                  aria-labelledby="property-type-filters-label"
                >
                  {(ptSubcategories || []).map((object: any) => (
                    <span key={object} className="usa-tag">
                      {object}
                    </span>
                  ))}
                  {(ptCategories || []).map((object: any) => (
                    <span key={object} className="usa-tag">
                      {object}
                    </span>
                  ))}
                </div>{" "}
              </div>
            ) : (
              <div
                className={styles.filtersContainer}
                id="property-type-filters"
                aria-labelledby="property-type-filters-label"
              >
                <span className="usa-tag">All</span>
              </div>
            )}
            <label className={styles.filtersLabel} id="GFA-filters-label">
              Gross Floor Area (sq. ft.)
            </label>
            {(gfaGroup2s && gfaGroup2s.length >= 1) ||
            (gfaGroups && gfaGroups.length >= 1) ? (
              <div>
                <div
                  className={styles.filtersContainer}
                  id="GFA-filters"
                  aria-labelledby="GFA-filters-label"
                >
                  {(gfaGroup2s || []).map((object: any) => (
                    <span key={object} className="usa-tag">
                      {object}
                    </span>
                  ))}
                  {(gfaGroups || []).map((object: any) => (
                    <span key={object} className="usa-tag">
                      {object}
                    </span>
                  ))}
                </div>{" "}
              </div>
            ) : (
              <div
                className={styles.filtersContainer}
                aria-labelledby="GFA-filters-label"
                id="GFA-filters"
              >
                <span className="usa-tag">All</span>
              </div>
            )}
            {location === "state" ? (
              <div>
                <label className={styles.filtersLabel} id="state-filters-label">
                  States
                </label>
                {stateProvinceNames && stateProvinceNames.length >= 1 ? (
                  <div>
                    <div
                      className={styles.filtersContainer}
                      id="state-filters"
                      aria-labelledby="state-filters-label"
                    >
                      {stateProvinceNames.length <= 5 ? (
                        stateProvinceNames.map((object: any) => (
                          <span key={object} className="usa-tag">
                            {object}
                          </span>
                        ))
                      ) : (
                        <span key="state-tag" className="usa-tag">
                          {stateProvinceNames.length} states
                        </span>
                      )}
                    </div>{" "}
                  </div>
                ) : (
                  <div
                    className={styles.filtersContainer}
                    id="state-filters"
                    aria-labelledby="state-filters-label"
                  >
                    <span className="usa-tag">All</span>
                  </div>
                )}
              </div>
            ) : location === "csa" ? (
              <div>
                <label className={styles.filtersLabel} id="cbsa-filters-label">
                  CBSAs
                </label>
                {(csa_area && csa_area.length >= 1) ||
                (csa_city && csa_city.length >= 1) ? (
                  <div>
                    <div
                      className={styles.filtersContainer}
                      id="cbsa-filters"
                      aria-labelledby="cbsa-filters-label"
                    >
                      {(csa_area || []).map((object: any) => (
                        <span key={object} className="usa-tag">
                          {object}
                        </span>
                      ))}
                      {(csa_city || []).map((object: any) => (
                        <span key={object} className="usa-tag">
                          {object}
                        </span>
                      ))}
                    </div>{" "}
                  </div>
                ) : (
                  <div
                    className={styles.filtersContainer}
                    id="cbsa-filters"
                    aria-labelledby="cbsa-filters-label"
                  >
                    <span className="usa-tag">All</span>
                  </div>
                )}
              </div>
            ) : location === "climate" ? (
              <div>
                <label
                  className={styles.filtersLabel}
                  id="climate-filters-label"
                >
                  Climate Zones
                </label>
                {climateZone && climateZone.length >= 1 ? (
                  <div>
                    <div
                      className={styles.filtersContainer}
                      id="climate-filters"
                      aria-labelledby="climate-filters-label"
                    >
                      {climateZone.length <= 5 ? (
                        climateZone.map((object: any) => (
                          <span key={object} className="usa-tag">
                            {object}
                          </span>
                        ))
                      ) : (
                        <span key="climate-tag" className="usa-tag">
                          {climateZone.length} climate zones
                        </span>
                      )}
                    </div>{" "}
                  </div>
                ) : (
                  <div
                    className={styles.filtersContainer}
                    id="climate-filters"
                    aria-labelledby="climate-filters-label"
                  >
                    <span className="usa-tag">All</span>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
            <label
              className={styles.filtersLabel}
              id="energy-star-certified-filters-label"
            >
              ENERGY STAR Certified?
            </label>
            <div
              className={styles.filtersContainer}
              id="energy-star-certified-filters"
              aria-labelledby="energy-star-certified-filters-label"
            >
              <span className="usa-tag">
                {isEnergyStarCertified === true
                  ? "Yes"
                  : isEnergyStarCertified === false
                  ? "No"
                  : "Show All"}
              </span>
            </div>
            <label
              className={styles.filtersLabel}
              id="year-built-filters-label"
            >
              Years Built
            </label>
            {yearBuiltGroups && yearBuiltGroups.length >= 1 ? (
              <div>
                <div
                  className={styles.filtersContainer}
                  id="year-built-filters"
                  aria-labelledby="year-built-filters-label"
                >
                  {yearBuiltGroups.map((object: any) => (
                    <span key={object} className="usa-tag">
                      {object}
                    </span>
                  ))}
                </div>{" "}
              </div>
            ) : (
              <div
                className={styles.filtersContainer}
                id="year-built-filters"
                aria-labelledby="year-built-filters-label"
              >
                <span className="usa-tag">All</span>
              </div>
            )}

            <label
              className={styles.filtersLabel}
              id="weekly-hours-filters-label"
            >
              Weekly Operating Hours
            </label>
            {weeklyHoursGroup && weeklyHoursGroup.length >= 1 ? (
              <div>
                <div
                  className={styles.filtersContainer}
                  id="weekly-hours-filters"
                  aria-labelledby="weekly-hours-filters-label"
                >
                  {weeklyHoursGroup.map((object: any) => (
                    <span key={object} className="usa-tag">
                      {object}
                    </span>
                  ))}
                </div>{" "}
              </div>
            ) : (
              <div
                className={styles.filtersContainer}
                id="weekly-hours-filters"
                aria-labelledby="weekly-hours-filters-label"
              >
                <span className="usa-tag">All</span>
              </div>
            )}
            {/* <label
              className={styles.filtersLabel}
              htmlFor="worker-density-filters"
            >
              Worker Density
            </label>
            {workerDensityGroups && workerDensityGroups.length >= 1 ? (
              <div>
                <div
                  className={styles.filtersContainer}
                  id="worker-density-filters"
                >
                  {workerDensityGroups.map((object: any) => (
                    <span key={object} className="usa-tag">
                      {object}
                    </span>
                  ))}
                </div>{" "}
              </div>
            ) : (
              <div
                className={styles.filtersContainer}
                id="worker-density-filters"
              >
                <span className="usa-tag">All</span>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainContent;
