import React, { useState, useEffect } from "react";
import "react-range-slider-input/dist/style.css";
import RangeSlider from "react-range-slider-input";
import { useAppContext, useDispatchContext } from "../../AppProvider";
import {
  yearReportedRadios,
} from "../../assets/data/filters";

const TrendRangeSlider = () => {
  const { trendStartYear, trendEndYear } = useAppContext();
  const dispatch = useDispatchContext();

  const MIN = Number(yearReportedRadios[yearReportedRadios.length - 1].value);
  const MAX = Number(yearReportedRadios[0].value); 

  const [sliderKey, setSliderKey] = useState(0);

  const handleSliderChange = (values: any) => {
    const [start, end] = values;
    dispatch({ type: "SET_TRENDS_START", payload: start });
    dispatch({ type: "SET_TRENDS_END", payload: end });
  };

  useEffect(() => {
    setSliderKey((prevKey) => prevKey + 1);
  }, [trendStartYear, trendEndYear]);

  return (
    <fieldset>
      <legend>Data Years</legend>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
        <span>{trendStartYear}</span>
        <span>{trendEndYear}</span>
      </div>
      <RangeSlider
        id="trend-year-slider"
        key={sliderKey} 
        min={MIN}
        max={MAX}
        defaultValue={[trendStartYear, trendEndYear]}
        onInput={handleSliderChange}
        className="range-slider"
        thumbsDisabled={[false, false]}
        rangeSlideDisabled={false}
      />
    </fieldset>
  );
};

export default TrendRangeSlider;
