import React, { FC, useEffect, useState, useRef } from "react";
import styles from "./barChart.module.scss";
import * as d3 from "d3";
import { energyUseIntensity } from "../../assets/data/filters";
import SortDescendingIcon from "../../assets/icons/sortDescending";
import SortAscendingIcon from "../../assets/icons/sortAscending";
import * as htmlToImage from "html-to-image";
import { CircularProgress } from "@mui/material";

interface BarChartProps {
  groupBy: any;
  results: any;
  readableGroupBy: any;
  metric: any;
  max: any;
  min: any;
  isMobile: any;
  setSortedChartData: any;
  caption: any;
  chartTitle: any;
  chartCount: any;
}

const BarChart: FC<BarChartProps> = ({
  isMobile,
  min,
  max,
  groupBy,
  results,
  readableGroupBy,
  metric,
  setSortedChartData,
  caption,
  chartCount,
  chartTitle,
}) => {
  let [containerWidth, setContainerWidth] = useState(50);
  let [isAscending, setIsAscending] = useState(true);
  let pluralGroupBy: string = "";
  const chartRef = useRef(null);
  let [downloading, setDownloading] = useState(false);

  useEffect(() => {
    setContainerWidth(
      d3.select("#barChartContainer").node().getBoundingClientRect().width
    );
  }, []);

  const downloadImage = (dataUrl: any, filename: string) => {
    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = filename;
    link.click();
  };

  const handleExportImage = () => {
    if (typeof window !== "undefined" && (window as any).dataLayer) {
      (window as any).dataLayer.push({
        event: "export_image_barChart",
        category: "Downloads",
        action: "Click",
        label: "User Exported Bar chart",
      });
    } else {
      console.warn("GTM not loaded yet");
    }

    exportImage();
  };

  const exportImage = () => {
    const captionElement = document.querySelector(
      `.${styles.chartCaption}`
    ) as HTMLElement;
    const countElement = document.querySelector(
      `.${styles.countCaption}`
    ) as HTMLElement;
    const titleElement = document.querySelector(
      `.${styles.chartTitle}`
    ) as HTMLElement;

    setDownloading(true);

    // Temporarily show the caption
    if (captionElement) {
      captionElement.style.display = "block";
    }
    if (countElement) {
      countElement.style.display = "block";
    }
    if (titleElement) {
      titleElement.style.display = "block";
    }

    const filename = `bar-chart-${metric}-${new Date()
      .toISOString()
      .substring(0, 10)}.png`;

    requestAnimationFrame(() => {
      if (chartRef.current) {
        htmlToImage
          .toPng(chartRef.current)
          .then((dataUrl) => {
            downloadImage(dataUrl, filename);
          })
          .catch((error) => {
            console.error("oops, something went wrong!", error);
          })
          .finally(() => {
            if (captionElement) {
              captionElement.style.display = "none";
            }
            if (countElement) {
              countElement.style.display = "none";
            }
            if (titleElement) {
              titleElement.style.display = "none";
            }
          });
      }
    });
    setDownloading(false);
  };

  let formatNumber = (d: any) => {
    if (metric === "energyStarScore" || metric === "percentElectricity" || metric === "water_score" ) {
      return Math.round(d);
    } else {
      return (Math.round(d * 10) / 10).toFixed(1);
    }
  };

  let checkIfSummary = (d: any) => {
    if (d.toLowerCase().includes("all ") || d.toLowerCase() === "all") {
      return true;
    } else {
      return false;
    }
  };

  let checkIfValidNUM = (d: any) => {
    if (d > 0 && isFinite(d) && d !== undefined && !isNaN(d)) {
      return true;
    } else {
      return false;
    }
  };

  let handleResize = () => {
    const barChartContainer = d3.select("#barChartContainer").node();
    if (barChartContainer) {
      setContainerWidth(barChartContainer.getBoundingClientRect().width);
      d3.select(".tooltip").style("opacity", 0);
    }
  };

  window.addEventListener("resize", handleResize);

  let data: any = [];

  if (groupBy === "ptCategory") {
    pluralGroupBy = "All Selected Property Types";
  }
  if (groupBy === "ptSubcategory") {
    pluralGroupBy = "All Selected Property Type Subcategories";
  }
  if (groupBy === "gfaGroup") {
    pluralGroupBy = "All Selected Gross Floor Areas";
  }
  if (groupBy === "stateProvinceName") {
    pluralGroupBy = "All Selected States";
  }
  if (groupBy === "csa_city") {
    pluralGroupBy = "All Selected CBSAs";
  }
  if (groupBy === "yearBuiltGroup") {
    pluralGroupBy = "All Selected Years Built";
  }
  if (groupBy === "climateZone") {
    pluralGroupBy = "All Selected Climate Zones";
  }

  data = results.getResults.results.reduce((acc: any, d: any) => {
    if (d.group === "All") {
      acc.push({
        group: pluralGroupBy,
        median: d.median,
        rowCount: d.rowCount,
      });
    } else {
      acc.push({ group: d.group, median: d.median, rowCount: d.rowCount });
    }
    return acc;
  }, []);

  let gfaSortOrder = [
    "1,000 - 4,999",
    "5,000 - 9,999",
    "10,000 - 24,999",
    "25,000 - 49,999",
    "50,000 - 99,999",
    "100,000 - 199,999",
    "200,000 - 499,999",
    "500,000 - 999,999",
    "1,000,000+",
  ];

  //  initial sort
  data.sort(function (a: any, b: any) {
    if (checkIfSummary(a.group) !== checkIfSummary(b.group)) {
      return checkIfSummary(a.group) ? -1 : 1;
    } else if (a.group.includes("Before") !== b.group.includes("Before")) {
      return a.group.includes("Before") ? -1 : 1;
    } else if (a.group.includes("500,000+") !== b.group.includes("500,000+")) {
      return a.group.includes("500,000+") ? 1 : -1;
    } else if (groupBy === "gfaGroup") {
      return gfaSortOrder.indexOf(a.group) - gfaSortOrder.indexOf(b.group);
    } else {
      if (a.group > b.group) {
        return 1;
      } else if (a.group < b.group) {
        return -1;
      }
      return 0;
    }
  });

  useEffect(() => {
    setSortedChartData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let yMargin = { top: 37, bottom: 50 };
  const heightRef = useRef(0);
  const widthRef = useRef(0);
  let maxLabelWidth = 0;

  heightRef.current = isMobile ? 500 : data.length * 35;

  useEffect(() => {
    widthRef.current = containerWidth - maxLabelWidth - 10 - 40;
  }, [containerWidth, maxLabelWidth]);

  // Y axis
  const y = d3
    .scaleBand()
    .range([0, heightRef.current])
    .domain(data.map((d: any) => d.group))
    .padding(0.1);

  // Draw chart
  useEffect(() => {
    let height = heightRef.current;
    let width = widthRef.current;

    if (height > 0) {
      d3.select("#barChart svg").remove();
      d3.select(".tooltip").remove();

      let tooltip = d3
        .select("#barChart")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);

      // create fake y axis to get length of labels
      // append the svg object to the body of the page
      let svgYAxis = d3.select("#fakeBarChart").append("svg");

      svgYAxis.append("g").call(d3.axisLeft(y)).attr("class", "fakeYAxis");

      svgYAxis
        .append("text")
        .attr("class", "fakeYAxisLabel")
        .text(readableGroupBy);

      d3.selectAll(".fakeYAxis .tick")
        .select("text")
        .attr("class", "fakeYAxisLabel")
        .style("text-anchor", "start");

      let yAxisLabel = document.querySelectorAll(".fakeYAxisLabel");
      let yAxisWidths: any = [];

      yAxisLabel.forEach((d: any) => {
        yAxisWidths.push(d.getBoundingClientRect().width);
      });

      let maxLabelWidth = Math.max(...yAxisWidths);

      let xMargin = { right: 40, left: maxLabelWidth + 10 };
      width = containerWidth - xMargin.left - xMargin.right;

      svgYAxis.remove();
      d3.selectAll(".fakeYAxis").remove();

      // append the svg object to the body of the page
      let svg = d3
        .select("#barChart")
        .append("svg")
        .attr("aria-describedby", "chartDesc")
        .attr("width", width + xMargin.left + xMargin.right)
        .attr("height", height + yMargin.top + yMargin.bottom)
        .append("g")
        .attr(
          "transform",
          "translate(" + xMargin.left + "," + yMargin.top + ")"
        );

      // Add X axis
      let x = d3.scaleLinear().domain([min, max]).range([0, width]).nice();
      svg
        .append("g")
        .attr("transform", "translate(0, 0)")
        .style("text-anchor", "middle")
        .call(d3.axisTop(x))
        .attr("class", "xAxisTop")
        .selectAll("text")
        .attr("class", "xlabel")
        .attr("transform", "translate(5,0)rotate(0)")
        .style("text-anchor", "end");

      svg
        .append("g")
        .attr("transform", "translate(0," + height + ")")
        .style("text-anchor", "middle")
        .call(d3.axisBottom(x))
        .attr("class", "xAxisBottom")
        .selectAll("text")
        .attr("class", "xlabel")
        .attr("transform", "translate(5,0)rotate(0)")
        .style("text-anchor", "end");

      svg
        .append("text")
        .attr("y", -25)
        .attr("x", -4)
        .attr("class", "xTitle")
        .style("text-anchor", "start")
        .attr("transform", "translate(0, 0)")
        .attr("fill", "black")
        .attr("font-weight", "bold")
        .text(
          `${energyUseIntensity.find((x) => x.name === metric)?.value} ${
            energyUseIntensity.find((x) => x.name === metric)?.unit
          }`
        );

      svg.append("g").call(d3.axisLeft(y)).attr("class", "yAxis");

      d3.selectAll(".yAxis .tick")
        .select("text")
        .style("text-anchor", "start")
        .attr("class", "ylabel")
        .attr("transform", "translate(-" + maxLabelWidth + " ,0)");
      d3.selectAll(".yAxis .tick").select("line").attr("visibility", "hidden");

      svg
        .append("text")
        .attr("y", -5)
        .attr("x", -10)
        .attr("class", "yTitle")
        .style("text-anchor", "start")
        .attr("transform", "translate(-" + maxLabelWidth + " ,0)")
        .attr("fill", "black")
        .attr("font-weight", "bold")
        .text(readableGroupBy);

      // Bars
      let bars = svg
        .selectAll("myRect")
        .data(data)
        .enter()
        .append("rect")
        .attr("class", "bar")
        .attr("x", x(min))
        .attr("y", function (d: any) {
          return y(d.group);
        })
        .attr("width", function (d: any) {
          return checkIfValidNUM(x(d.median)) ? x(d.median) : 0;
        })
        .attr("height", y.bandwidth())
        .attr("fill", function (d: any) {
          return checkIfSummary(d.group) ? "#162E51" : "#2786AA";
        });
      let tooltipWidth: any;
      let tooltipHeight: any;

      if (!isMobile) {
        bars
          .on("mouseenter", (event: any, d: any) => {
            return tooltip.style("opacity", 1);
          })
          .on("mousemove", (event: any, d: any) => {
            // Calculate the position of the tooltip
            if (tooltip && tooltip !== null) {
              tooltipWidth = tooltip.node().getBoundingClientRect().width;
              tooltipHeight = tooltip.node().getBoundingClientRect().height;
            }
            const tooltipOffsetX = -200;
            const tooltipOffsetY = 85;

            let tooltipTop = event.clientY - tooltipHeight - tooltipOffsetY;
            let tooltipLeft = event.clientX - tooltipWidth / 2 + tooltipOffsetX;

            return tooltip
              .style("top", tooltipTop + "px")
              .style("left", tooltipLeft + "px")
              .html(
                "<b>" +
                  d.group +
                  " (" +
                  d.rowCount +
                  " properties)" +
                  "</b>" +
                  "<span class='tooltip-median ml-10'><b>" +
                  formatNumber(d.median) +
                  "</b></span>"
              );
          })
          .on("mouseleave", () => {
            return tooltip.style("opacity", 0);
          });
      }

      d3.selectAll("g.xAxisTop g.tick")
        .append("line")
        .attr("class", "gridline")
        .attr("x1", 0)
        .attr("y1", height)
        .attr("x2", 0)
        .attr("y2", 0)
        .attr("stroke", "#DEDEDE");
    }
    // eslint-disable-next-line
  }, [groupBy, results, containerWidth, max, min, isMobile]);

  // // sorting logic
  d3.select("#byGroupName").on("click", () => {
    d3.select("#byGroupName")
      .classed("usa-button--active", true)
      .classed("usa-button--outline", false);
    d3.select("#byMedian")
      .classed("usa-button--active", false)
      .classed("usa-button--outline", true);
    data.sort(function (a: any, b: any) {
      if (checkIfSummary(a.group) !== checkIfSummary(b.group)) {
        return checkIfSummary(a.group) ? -1 : 1;
      } else if (a.group.includes("Before") !== b.group.includes("Before")) {
        return a.group.includes("Before") ? -1 : 1;
      } else if (
        a.group.includes("500,000+") !== b.group.includes("500,000+")
      ) {
        return a.group.includes("500,000+") ? 1 : -1;
      } else if (groupBy === "gfaGroup") {
        return gfaSortOrder.indexOf(a.group) - gfaSortOrder.indexOf(b.group);
      } else {
        if (a.group > b.group) {
          return 1;
        } else if (a.group < b.group) {
          return -1;
        } else {
          return 0;
        }
      }
    });
    y.domain(
      data.map(function (d: any) {
        return d.group;
      })
    );
    d3.select("#barChart")
      .selectAll(".bar")
      .transition()
      .duration(500)
      .attr("y", function (d: any, i: number) {
        return y(d.group);
      });

    d3.select("#barChart")
      .selectAll("g .yAxis")
      .transition()
      .duration(500)
      .call(d3.axisLeft(y));
  });

  d3.select("#byMedian").on("click", function () {
    setIsAscending(!isAscending);
    d3.select("#byMedian")
      .classed("usa-button--active", true)
      .classed("usa-button--outline", false);
    d3.select("#byGroupName")
      .classed("usa-button--active", false)
      .classed("usa-button--outline", true);
    data.sort(function (a: any, b: any) {
      return isAscending
        ? d3.ascending(a.median, b.median)
        : d3.descending(a.median, b.median);
    });
    y.domain(
      data.map(function (d: any) {
        return d.group;
      })
    );
    d3.select("#barChart")
      .selectAll(".bar")
      .transition()
      .duration(500)
      .attr("y", function (d: any, i: number) {
        return y(d.group);
      });
    d3.select("#barChart")
      .selectAll("g .yAxis")
      .transition()
      .duration(500)
      .call(d3.axisLeft(y));
  });

  return (
    <div className={styles.BarChart} id="barChartContainer">
      <div className="chart-header">
        <div className="sorting-container">
          <h5> Sort: </h5>
          <ul
            className={`sort-button-group usa-button-group usa-button-group--segmented ${styles.buttonGroup}`}
          >
            <li className="usa-button-group__item">
              <button
                id="byGroupName"
                type="button"
                className="usa-button usa-button--active sortButton"
              >
                Name
              </button>
            </li>
            <li className="usa-button-group__item">
              <button
                id="byMedian"
                type="button"
                className="usa-button usa-button--outline sortButton"
              >
                Value
                {isAscending ? (
                  <SortDescendingIcon scale={0.8} />
                ) : (
                  <SortAscendingIcon scale={0.8} />
                )}
              </button>
            </li>
          </ul>
        </div>

      </div>
      <p aria-hidden={false} id="chartDesc" className="chartDesc">
        Median {energyUseIntensity.find((x) => x.name === metric)?.value}{" "}
        grouped by {readableGroupBy}.
      </p>

      {!downloading ? (
        <>
        <div ref={chartRef} className={styles.chartContainer} id="barChart">
          <h2 className={styles.chartTitle}>{chartTitle}</h2>
          <p className={styles.countCaption}>{chartCount}</p>
          <p className={styles.chartCaption}>{caption}</p>
        </div>
        <div className="chart-footer">
        <button
           className="usa-button usa-button--active downloadImgButton"
          onClick={handleExportImage}
        >
          Export Chart as Image
        </button>
        </div>
        </>
      ) : (
        <div className="loading-container">
          <div className="loading-container-inner">
            <p>Downloading your chart...</p>
            <CircularProgress />
          </div>
        </div>
      )}

      <div className={styles.chartContainer} id="fakeBarChart"></div>

    </div>
  );
};

export default BarChart;
