import React, { FC, useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import styles from "./trendChart.module.scss";
import { GetTrendsQuery } from "../../graphql/generated/graphql";
import { useTrendsQuery, useAppContext } from "../../AppProvider";
import * as htmlToImage from "html-to-image";
import { energyUseIntensity } from "../../assets/data/filters";
import { CircularProgress } from "@mui/material";

interface TrendChartProps {
  caption: any;
  chartTitle: any;
  chartCount: any;
  groupBy: any;
}

interface DataPoint {
  group: string;
  yearreported: number;
  median: number;
  mean: number;
  fifthpercentile: number;
  twentyfifthpercentile: number;
  seventyfifthpercentile: number;
  ninetyfifthpercentile: number;
}

const TrendChart: FC<TrendChartProps> = ({
  caption,
  chartTitle,
  chartCount,
  groupBy,
}) => {
  const {
    withMeanTrend,
    withMedianTrend,
    withFifthPercentileTrend,
    withTwentyFifthPercentileTrend,
    withSeventyFifthPercentileTrend,
    withNinetyFifthPercentileTrend,
    metric,
  } = useAppContext();
  const { data } = useTrendsQuery<GetTrendsQuery>();
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [containerWidth, setContainerWidth] = useState(50);
  const [containerHeight, setContainerHeight] = useState(50);
  const [marginRight, setMarginRight] = useState(120);
  const chartRef = useRef(null);
  let [downloading, setDownloading] = useState(false);
  let pluralGroupBy: string = "";
  const [hasHighlightedTrends, setHasHighlightedTrends] = useState(false);



  const resetHighlight = () => {
    if (svgRef.current) {
      d3.select(svgRef.current)
        .selectAll(".g")
        .classed(styles.active, false)
        .style("opacity", 1)
        .selectAll("g")
        .style("opacity", 0);
      setHasHighlightedTrends(false);
    }
  };

  const selectedTrend = withMeanTrend
    ? "Mean"
    : withMedianTrend
    ? "Median"
    : withFifthPercentileTrend
    ? "5th Percentile"
    : withTwentyFifthPercentileTrend
    ? "25th Percentile"
    : withSeventyFifthPercentileTrend
    ? "75th Percentile"
    : withNinetyFifthPercentileTrend
    ? "95th Percentile"
    : "Median";

  const getTrendQueryName = (selectedTrend: string) => {
    return selectedTrend
      .toLowerCase()
      .replace(/\s+/g, "")
      .replace(/(\d+)(st|nd|rd|th)/g, (_, num) => {
        const numMap: any = {
          5: "fifth",
          25: "twentyfifth",
          75: "seventyfifth",
          95: "ninetyfifth",
        };
        return numMap[num] || num;
      });
  };

  const selectedTrendQuery = getTrendQueryName(selectedTrend);

  useEffect(() => {
    setContainerWidth(
      d3.select("#trendChartContainer").node().getBoundingClientRect().width
    );
    setContainerHeight(
      d3.select("#trendChartContainer").node().getBoundingClientRect().height
    );
  }, []);

  const downloadImage = (dataUrl: any, filename: string) => {
    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = filename;
    link.click();
  };

  const handleExportImage = () => {
    if (typeof window !== "undefined" && (window as any).dataLayer) {
      (window as any).dataLayer.push({
        event: "export_image_trendChart",
        category: "Downloads",
        action: "Click",
        label: "User Exported Trend chart",
      });
    } else {
      console.warn("GTM not loaded yet");
    }

    exportImage();
  };

  const exportImage = () => {
    const captionElement = document.querySelector(
      `.${styles.chartCaption}`
    ) as HTMLElement;
    const countElement = document.querySelector(
      `.${styles.countCaption}`
    ) as HTMLElement;
    const titleElement = document.querySelector(
      `.${styles.chartTitle}`
    ) as HTMLElement;
    setDownloading(true);

    // Temporarily show the caption
    if (captionElement) {
      captionElement.style.display = "block";
    }

    if (countElement) {
      countElement.style.display = "block";
    }

    if (titleElement) {
      titleElement.style.display = "block";
    }

    const filename = `trends-${metric}-${new Date()
      .toISOString()
      .substring(0, 10)}.png`;

    if (chartRef.current) {
      htmlToImage
        .toPng(chartRef.current)
        .then((dataUrl) => {
          downloadImage(dataUrl, filename);
        })
        .catch((error) => {
          console.error("oops, something went wrong!", error);
        })
        .finally(() => {
          // Hide the caption again after the image is generated
          if (captionElement) {
            captionElement.style.display = "none";
          }
          if (countElement) {
            countElement.style.display = "none";
          }
          if (titleElement) {
            titleElement.style.display = "none";
          }
        });
    }
    setDownloading(false);
  };



  const handleResize = () => {
    const trendChartContainer = d3.select("#trendChartContainer").node();
    if (trendChartContainer) {
      setContainerWidth(trendChartContainer.getBoundingClientRect().width);
    }
  };

  window.addEventListener("resize", handleResize);

  if (groupBy === "ptCategory") {
    pluralGroupBy = "All Selected Property Types";
  }
  if (groupBy === "ptSubcategory") {
    pluralGroupBy = "All Selected Property Type Subcategories";
  }
  if (groupBy === "gfaGroup") {
    pluralGroupBy = "All Selected Gross Floor Areas";
  }
  if (groupBy === "stateProvinceName") {
    pluralGroupBy = "All Selected States";
  }
  if (groupBy === "csa_city") {
    pluralGroupBy = "All Selected CBSAs";
  }
  if (groupBy === "yearBuiltGroup") {
    pluralGroupBy = "All Selected Years Built";
  }
  if (groupBy === "climateZone") {
    pluralGroupBy = "All Selected Climate Zones";
  }

  useEffect(() => {
    if (!data?.getTrends?.results || data.getTrends.results.length === 0)
      return;

    let trends = data.getTrends.results;

    if (trends.length === 2) {
      trends = trends.filter((trend) => trend.group !== "All");
    }

    const formatNumber = (d: any) => {
      if (metric === "energyStarScore" || metric === "percentElectricity" || metric === "water_score" ) {
        return Math.round(d);
      } else {
        return (Math.round(d * 10) / 10).toFixed(1);
      }
    };

    const svg = d3
      .select(svgRef.current)
      .attr("viewBox", `0 0 ${containerWidth} ${containerHeight}`)
      .attr("style", "max-width: 100%; height: auto; font: 10px sans-serif;");

    svg.selectAll("*").remove();

    const flattenedTrends = trends.flatMap((trend) => {
      const years = trend.yearreported || [];
      const medians = trend.median || [];
      const means = trend.mean || [];
      const fifthPercentiles = trend.fifthpercentile || [];
      const twentyFifthPercentiles = trend.twentyfifthpercentile || [];
      const seventyFifthPercentiles = trend.seventyfifthpercentile || [];
      const ninetyFifthPercentiles = trend.ninetyfifthpercentile || [];

      return years.map((year, i) => ({
        group: trend.group === "All" ? pluralGroupBy : trend.group || "unknown",
        yearreported: year,
        median: medians[i] ?? 0,
        mean: means[i] ?? 0,
        fifthpercentile: fifthPercentiles[i],
        twentyfifthpercentile: twentyFifthPercentiles[i],
        seventyfifthpercentile: seventyFifthPercentiles[i],
        ninetyfifthpercentile: ninetyFifthPercentiles[i],
      }));
    });

    // Calculate width of longest label
    const groupNames = flattenedTrends.map((d) => d.group);
    const longestLabelWidth =
      d3.max(
        groupNames.map((name) => {
          const text = svg
            .append("text")
            .attr("font-size", "12px")
            .attr("visibility", "hidden")
            .text(name);
          const bbox = text.node().getBBox();
          text.remove();
          return bbox.width;
        })
      ) || 120;

    setMarginRight(Math.max(120, longestLabelWidth + 20)); // Set margin-right to be sufficient for the longest label

    const x = d3
      .scaleUtc()
      .domain(
        d3.extent(
          flattenedTrends,
          (d: any) => new Date(d.yearreported, 0, 1)
        ) as [Date, Date]
      )
      .range([60, containerWidth - marginRight]);

    svg
      .append("g")
      .attr("transform", `translate(0,${containerHeight - 25})`)
      .call(
        d3
          .axisBottom(x)
          .ticks(d3.timeYear.every(1))
          .tickFormat(d3.timeFormat("%Y"))
          .tickSizeOuter(0)
          .tickSize(0)
      )
      .selectAll("text")
      .attr("font-size", "12px")
      .attr("class", "x-axis");
    function updateOpacity(eventType: string) {
      const hasActive =
        svg
          .selectAll(".g")
          .filter(function (this: SVGGElement) {
            return d3.select(this).classed(styles.active);
          })
          .size() > 0;

      svg.selectAll(".g").each(function (this: SVGGElement) {
        const currentElement = d3.select(this);
        const isActive = currentElement.classed(styles.active);
        const isHovered = currentElement.classed(styles.hovered);

        if (hasActive) {
          if (isActive || isHovered) {
            currentElement.transition().style("opacity", 1);
            currentElement.selectAll("g").transition().style("opacity", 1);
          } else {
            currentElement.transition().style("opacity", 0.1);
            currentElement.selectAll("g").transition().style("opacity", 0.1);
          }
        } else {
          if (isHovered) {
            currentElement.transition().style("opacity", 1);
            currentElement.selectAll("g").transition().style("opacity", 1);
          } else {
            currentElement
              .transition()
              .style("opacity", eventType === "mouseout" ? 1 : 0.1);
            currentElement.selectAll("g").transition().style("opacity", 0);
          }
        }
      });
      setHasHighlightedTrends(hasActive);
    }

    function groupByContinuity(data: DataPoint[]): {
      continuous: DataPoint[][];
      isolated: DataPoint[];
    } {
      const continuous: DataPoint[][] = [];
      const isolated: DataPoint[] = [];
      let currentGroup: DataPoint[] = [];

      data
        .sort((a, b) => a.yearreported - b.yearreported)
        .forEach((d, i, arr) => {
          if (i > 0 && d.yearreported === arr[i - 1].yearreported + 1) {
            currentGroup.push(d);
          } else {
            if (currentGroup.length > 1) continuous.push(currentGroup);
            else if (currentGroup.length === 1) isolated.push(currentGroup[0]);

            currentGroup = [d];
          }
        });

      if (currentGroup.length > 1) continuous.push(currentGroup);
      else if (currentGroup.length === 1) isolated.push(currentGroup[0]);

      return { continuous, isolated };
    }

    let y = d3
    .scaleLinear()
    .domain([
      d3.min(flattenedTrends, (d: any) => d[selectedTrendQuery]) * 0.99,
      d3.max(flattenedTrends, (d: any) => d[selectedTrendQuery]) * 1.01,
    ])
    .nice()
    .range([containerHeight - 25, 30]);

    const uswdsColors = [
      "#005ea2",
      "#d83933",
      "#00bde3",
      "#fa9441",
      "#04c585",
      "#8168b3",
      "#676cc8",
      "#0076d6",
      "#009ec1",
      "#538200",
      "#775540",
      "#ffbe2e",
      "#e66f0e",
      "#e52207",
      "#1a4480",
    ];

    const color = d3
      .scaleOrdinal()
      .domain(flattenedTrends.map((d) => d.group))
      .range(uswdsColors);

    const yAxis = d3.axisLeft(y).ticks(5).tickSize(0);

    svg
      .append("g")
      .attr("transform", `translate(35, 0)`)
      .call(yAxis)
      .call((g: any) => g.select(".domain").remove())
      .selectAll("text") 
      .attr("font-size", "12px")
      .attr("class", "y-axis");
    // Add y-axis label
    svg
      .append("text")
      .attr("x", 10)
      .attr("y", 15)
      .attr("text-anchor", "start")
      .attr("font-size", "12px")
      .attr("font-weight", "bold")
      .text(
        `${selectedTrend} ${
          energyUseIntensity.find((x) => x.name === metric)?.value
        } ${energyUseIntensity.find((x) => x.name === metric)?.unit}`
      );

    let lineGenerator = d3
      .line()
      .x((d: any) => x(new Date(d.yearreported, 0, 1)))
      .y((d: any) => y(d[selectedTrendQuery]))
      .defined((d: any, i: number, data: any) => {
        return d[selectedTrendQuery] != null;
      });

    const series = svg
      .append("g")
      .selectAll("g")
      .data(d3.group(flattenedTrends, (d: any) => d.group))
      .join("g");

    series.each(function (this: SVGGElement, d: any) {
      const groupData = d[1];
      const groupColor = color(d[0]) as string;

      const element = d3.select(this);
      const segments = groupByContinuity(groupData);

      element.attr("class", "g");

      segments.continuous.forEach((segment: any) => {
        if (segment.length > 1) {
          const isSummary = segment.some((obj: any) =>
            obj.group?.includes(pluralGroupBy)
          );

          element
            .append("path")
            .datum(segment)
            .attr("fill", "none")
            .attr("stroke", isSummary ? "#162E51" : groupColor)
            .attr("stroke-width", isSummary ? 2 : 1.5)
            .attr("d", lineGenerator);
        }
      });

      segments.isolated.forEach((point: any) => {
        const isSummary = point.group?.includes(pluralGroupBy);

        element.append("g").datum(point);

        element
          .append("circle")
          .attr("cx", x(new Date(point.yearreported, 0, 1)))
          .attr("cy", y(point[selectedTrendQuery]))
          .attr("r", isSummary ? 4 : 3)
          .attr("fill", isSummary ? "#162E51" : groupColor)
          .style("opacity", 1);
      });

      const latestPoint = d3.max(groupData, (d: any) => d.yearreported);
      const lastDataPoint = groupData.find(
        (d: any) => d.yearreported === latestPoint
      );
      // const isSummary = element.some((obj: any) => obj.group?.includes(pluralGroupBy));
      if (lastDataPoint) {
        element
          .append("text")
          .attr("x", x(new Date(lastDataPoint.yearreported, 0, 1)) + 20)
          .attr("y", y(lastDataPoint[selectedTrendQuery]))
          .attr("dy", "0.35em")
          .attr("text-anchor", "start")
          .attr("font-weight", "bold")

          .text(`${d[0]}`)
          .attr("class", `label ${styles.text}`)
          // .clone(true)
          .lower()
          .attr("fill", (d: any) =>
            d[0] === pluralGroupBy ? "#162E51" : groupColor
          );

        element
          .append("line")
          .attr("stroke", "gray")
          .attr("class", "connection")
          .attr("stroke-dasharray", "2,2")
          .attr("stroke-width", 1)
          .attr("x1", x(new Date(lastDataPoint.yearreported, 0, 1)) + 5)
          .attr("x2", x(new Date(lastDataPoint.yearreported, 0, 1)) + 15)
          .attr("y1", y(lastDataPoint[selectedTrendQuery]))
          .attr("y2", y(lastDataPoint[selectedTrendQuery]));
      }

      element
        .append("g")
        .attr("stroke-linecap", "round")
        .attr("stroke-linejoin", "round")
        .attr("text-anchor", "middle")
        .attr("class", styles.defaultG)
        .style("opacity", 0)
        .selectAll("text")
        .data(groupData)
        .join("text")
        .attr("font-size", "12px")
        .attr("class", styles.text)
        .attr("dy", "0.35em")
        .attr("x", (d: any) => x(new Date(d.yearreported, 0, 1)))
        .attr("y", (d: any) => y(d[selectedTrendQuery]))
        .text((d: any) => formatNumber(d[selectedTrendQuery]))
        .clone(true)
        .lower()
        .attr("fill", "blue")
        .attr("stroke", "white")
        .attr("stroke-width", 7);


      element
        .on("click", function (this: SVGGElement) {
          const clickedElement = d3.select(this);
          const wasActive = clickedElement.classed(styles.active);

          // Toggle the 'active' class on the clicked element
          clickedElement.classed(styles.active, !wasActive);
          clickedElement.raise();

          // Update opacity based on current state
          updateOpacity("click");
        })
        .on("mouseover", function (this: SVGGElement) {
          const hoveredElement = d3.select(this);
          hoveredElement.classed(styles.hovered, true);
          hoveredElement.raise();

          // Update opacity based on current state
          updateOpacity("mouseover");
        })
        .on("mouseout", function (this: SVGGElement) {
          const hoveredElement = d3.select(this);
          hoveredElement.classed(styles.hovered, false);

          // Update opacity based on current state
          updateOpacity("mouseout");
        });
    });

    const labels = svg.selectAll("text.label");
    const MIN_SPACING = 15;
    let labelPositions: { idx: number; x: number; y: number; yOrgPos: number; text: string; element: any }[] =
      [];

    // Collect initial label positions
    let idx = 0;
    labels.each(function (this: any) {
      const label = d3.select(this);
      const x = parseFloat(label.attr("x"));
      const y = parseFloat(label.attr("y"));
      const text = label.text().trim();
      labelPositions.push({ idx: idx++, x, y, yOrgPos:y, text, element: label });
    });

    //console.log(labelPositions.length);

    // Apply collision resolution efficiently
    if (labelPositions.length < 35) {  
      let prev: {x:number; y:number;} = {x:0, y:0};
      labelPositions
        // Sort labels by x first, then by y, then by label-name
        .sort((a, b) => a.x - b.x || a.y - b.y || a.text.localeCompare(b.text))
        // Shift y position if overlap, unless it goes beyond the bounds of the container
        .map((curr) => {
          if (curr.x === prev.x && (curr.y - prev.y) <= MIN_SPACING && prev.y < (containerHeight - MIN_SPACING*3)){
            curr.y = prev.y + MIN_SPACING;
          }        
          prev = {x: curr.x, y:curr.y};
          // Apply new label positions
          curr.element.attr("y",curr.y);

          return curr;
        })
      ;

      //console.log('after sort > map to change y:', labelPositions);

      // Update connection lines to point to new label positions
      series.each(function (this: SVGGElement, d: any) {
        const element = d3.select(this);
        const labelData = labelPositions.find(
          (pos) => pos.text === d[0]?.trim()
        );

        if (labelData) {
          element.select(".connection").attr("y2", labelData.y);
        }
      });
    }

    svg
      .transition()
      .duration(750)
      .attr("width", containerWidth)
      .attr("height", containerHeight)
      .attr("viewBox", `0 0 ${containerWidth} ${containerHeight}`);
  }, [
    data,
    containerWidth,
    containerHeight,
    marginRight,
    metric,
    selectedTrend,
    selectedTrendQuery,
    pluralGroupBy
  ]);

  return (
    <div className={styles.TrendChart}>
      {!downloading ? (
        <>
          <div
            ref={chartRef}
            id="trendChartContainer"
            className={styles.TrendChart}
          >
            <h2 className={styles.chartTitle}>{chartTitle}</h2>
            <p className={styles.chartCaption}>{caption}</p>
            <svg ref={svgRef}></svg>
          </div>
          <div className="chart-footer">
            {hasHighlightedTrends && (
              <button onClick={resetHighlight}   className="usa-button usa-button--outline downloadImgButton">
                Reset Highlight
              </button>
            )}
            <button
              className="usa-button usa-button--active downloadImgButton"
              onClick={handleExportImage}
            >
              Export Chart as Image
            </button>
          </div>
        </>
      ) : (
        <div className="loading-container">
          <div className="loading-container-inner">
            <p>Downloading your chart...</p>
            <CircularProgress />
          </div>
        </div>
      )}
    </div>
  );
};

export default TrendChart;
