import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./boxAndWhiskerPlot.module.scss";
import * as d3 from "d3";
import { energyUseIntensity } from "../../assets/data/filters";
import SortDescendingIcon from "../../assets/icons/sortDescending";
import SortAscendingIcon from "../../assets/icons/sortAscending";
import * as htmlToImage from "html-to-image";
import { CircularProgress } from "@mui/material";

interface BoxAndWhiskerPlotProps {
  groupBy: any;
  results: any;
  readableGroupBy: any;
  metric: any;
  isMobile: any;
  setSortedChartData: any;
  caption: any;
  chartTitle: any;
  chartCount: any;
}

const BoxAndWhiskerPlot: FC<BoxAndWhiskerPlotProps> = ({
  isMobile,
  readableGroupBy,
  metric,
  groupBy,
  results,
  setSortedChartData,
  caption,
  chartTitle,
  chartCount,
}) => {
  let [containerWidth, setContainerWidth] = useState(50);
  let [isAscending, setIsAscending] = useState(true);
  let pluralGroupBy: string = "";
  let [downloading, setDownloading] = useState(false);
  const chartRef = useRef(null);

  useEffect(() => {
    setContainerWidth(
      d3.select("#boxAndWhiskerPlotContainer").node().getBoundingClientRect()
        .width
    );
  }, []);

  const downloadImage = (dataUrl: any, filename: string) => {
    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = filename;
    link.click();
  };

  const handleExportImage = () => {
    if (typeof window !== "undefined" && (window as any).dataLayer) {
      (window as any).dataLayer.push({
        event: "export_image_boxAndWhiskerPlot",
        category: "Downloads",
        action: "Click",
        label: "User Exported Box and Whisker Plot chart",
      });
    } else {
      console.warn("GTM not loaded yet");
    }

    exportImage();
  };

  const exportImage = () => {
    const captionElement = document.querySelector(
      `.${styles.chartCaption}`
    ) as HTMLElement;
    const legendElement = document.querySelector(
      `.${styles.legendContainerDownload}`
    ) as HTMLElement;
    const countElement = document.querySelector(
      `.${styles.countCaption}`
    ) as HTMLElement;
    const titleElement = document.querySelector(
      `.${styles.chartTitle}`
    ) as HTMLElement;

    setDownloading(true);

    // Temporarily show the caption
    if (captionElement) {
      captionElement.style.display = "block";
    }

    if (legendElement) {
      legendElement.style.display = "block";
    }
    if (countElement) {
      countElement.style.display = "block";
    }
    if (titleElement) {
      titleElement.style.display = "block";
    }

    const filename = `box-and-whisker-plot-${metric}-${new Date()
      .toISOString()
      .substring(0, 10)}.png`;

    if (chartRef.current) {
      htmlToImage
        .toPng(chartRef.current)
        .then((dataUrl) => {
          downloadImage(dataUrl, filename);
        })
        .catch((error) => {
          console.error("oops, something went wrong!", error);
        })
        .finally(() => {
          // Hide the caption again after the image is generated
          if (captionElement) {
            captionElement.style.display = "none";
          }
          if (legendElement) {
            legendElement.style.display = "none";
          }
          if (countElement) {
            countElement.style.display = "none";
          }
          if (titleElement) {
            titleElement.style.display = "none";
          }
        });
    }
    setDownloading(false);
  };

  let formatNumber = (d: any) => {
    if (metric === "energyStarScore" || metric === "percentElectricity" || metric === "water_score" ) {
      return Math.round(d);
    } else {
      return (Math.round(d * 10) / 10).toFixed(1);
    }
  };

  let checkIfSummary = (d: any) => {
    if (d.toLowerCase().includes("all ") || d.toLowerCase() === "all") {
      return true;
    } else {
      return false;
    }
  };

  let checkIfValidNUM = (d: any) => {
    if (d > 0 && isFinite(d) && d !== undefined && !isNaN(d)) {
      return true;
    } else {
      return false;
    }
  };

  let handleResize = () => {
    const boxAndWhiskerPlotContainer = d3
      .select("#boxAndWhiskerPlotContainer")
      .node();
    if (boxAndWhiskerPlotContainer) {
      setContainerWidth(
        boxAndWhiskerPlotContainer.getBoundingClientRect().width
      );
    }
  };

  window.addEventListener("resize", handleResize);

  let data: any = [];

  if (groupBy === "ptCategory") {
    pluralGroupBy = "All Selected Property Types";
  }
  if (groupBy === "ptSubcategory") {
    pluralGroupBy = "All Selected Property Type Subcategories";
  }
  if (groupBy === "gfaGroup") {
    pluralGroupBy = "All Selected Gross Floor Areas";
  }
  if (groupBy === "stateProvinceName") {
    pluralGroupBy = "All Selected States";
  }
  if (groupBy === "yearBuiltGroup") {
    pluralGroupBy = "All Selected Years Built";
  }
  if (groupBy === "csa_city") {
    pluralGroupBy = "All Selected CBSAs";
  }
  if (groupBy === "climateZone") {
    pluralGroupBy = "All Selected Climate Zones";
  }

  data = results.getResults.results.reduce((acc: any, d: any) => {
    if (d.group === "All") {
      acc.push({
        group: pluralGroupBy,
        median: d.median,
        mean: d.mean,
        fifthPercentile: d.fifthPercentile,
        twentyFifthPercentile: d.twentyFifthPercentile,
        seventyFifthPercentile: d.seventyFifthPercentile,
        ninetyFifthPercentile: d.ninetyFifthPercentile,
        rowCount: d.rowCount,
      });
    } else {
      acc.push({
        group: d.group,
        median: d.median,
        mean: d.mean,
        fifthPercentile: d.fifthPercentile,
        twentyFifthPercentile: d.twentyFifthPercentile,
        seventyFifthPercentile: d.seventyFifthPercentile,
        ninetyFifthPercentile: d.ninetyFifthPercentile,
        rowCount: d.rowCount,
      });
    }
    return acc;
  }, []);

  let gfaSortOrder = [
    "1,000 - 4,999",
    "5,000 - 9,999",
    "10,000 - 24,999",
    "25,000 - 49,999",
    "50,000 - 99,999",
    "100,000 - 199,999",
    "200,000 - 499,999",
    "500,000 - 999,999",
    "1,000,000+",
  ];

  data.sort(function (a: any, b: any) {
    if (checkIfSummary(a.group) !== checkIfSummary(b.group)) {
      return checkIfSummary(a.group) ? -1 : 1;
    } else if (a.group.includes("Before") !== b.group.includes("Before")) {
      return a.group.includes("Before") ? -1 : 1;
    } else if (a.group.includes("500,000+") !== b.group.includes("500,000+")) {
      return a.group.includes("500,000+") ? 1 : -1;
    } else if (groupBy === "gfaGroup") {
      return gfaSortOrder.indexOf(a.group) - gfaSortOrder.indexOf(b.group);
    } else {
      if (a.group > b.group) {
        return 1;
      } else if (a.group < b.group) {
        return -1;
      } else {
        return 0;
      }
    }
  });

  useEffect(() => {
    setSortedChartData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set the dimensions and margins of the graph
  let yMargin = { top: 37, bottom: 50 };
  const heightRef = useRef(0);
  const widthRef = useRef(0);

  heightRef.current = isMobile ? 500 : data.length * 35;

  let globalCounts: any = [];
  let groupCounts = [];
  for (let i in data) {
    globalCounts.push(+data[i].ninetyFifthPercentile);
    groupCounts.push(data[i].group);
  }

  let heightProportions = heightRef.current / data.length;
  let barHeight: number;
  if (data.length > 6) {
    barHeight = heightProportions - heightProportions / 2;
  } else {
    barHeight = 25;
  }

  // y axis
  let yScale = d3
    .scaleBand()
    .domain(groupCounts)
    .range([0, heightRef.current])
    .paddingInner(0.8)
    .paddingOuter(0.8);

  // fake y axis to calculate left margin and axis width
  let svgYAxis = d3.select("#fakeBoxAndWhiskerPlot").append("svg");

  svgYAxis.append("g").call(d3.axisLeft(yScale)).attr("class", "fakeYAxis");

  d3.selectAll(".fakeYAxis .tick")
    .select("text")
    .attr("class", "fakeYAxisLabel")
    .style("text-anchor", "start");

  svgYAxis.append("text").attr("class", "fakeYAxisLabel").text(readableGroupBy);

  let yAxisLabel = document.querySelectorAll(".fakeYAxisLabel");
  let yAxisWidths: any = [];

  yAxisLabel.forEach((d: any) => {
    yAxisWidths.push(d.getBoundingClientRect().width);
  });

  let maxLabelWidth = Math.max(...yAxisWidths);

  let xMargin = { right: 40, left: maxLabelWidth + 10 };
  widthRef.current = containerWidth - xMargin.left - xMargin.right;

  // x axis
  let min = 0;
  let max = d3.max(globalCounts);
  let xScale = d3
    .scaleLinear()
    .domain([min, max])
    .range([0, widthRef.current])
    .nice();

  svgYAxis.remove();
  d3.selectAll(".fakeYAxis").remove();

  // whiskers and the median
  let horizontalLineConfigs = [
    // Top whisker
    {
      y1: function (datum: any) {
        return yScale(datum.group) - barHeight / 2;
      },
      x1: function (datum: any) {
        return xScale(+datum.fifthPercentile);
      },
      y2: function (datum: any) {
        return yScale(datum.group) + barHeight / 2;
      },
      x2: function (datum: any) {
        return xScale(+datum.fifthPercentile);
      },
      class: "fifth",
    },
    // Median line
    {
      y1: function (datum: any) {
        return yScale(datum.group) - barHeight / 2;
      },
      x1: function (datum: any) {
        return xScale(datum.median);
      },
      y2: function (datum: any) {
        return yScale(datum.group) + barHeight / 2;
      },
      x2: function (datum: any) {
        return xScale(datum.median);
      },
      class: "median",
    },
    // Bottom whisker
    {
      y1: function (datum: any) {
        return yScale(datum.group) - barHeight / 2;
      },
      x1: function (datum: any) {
        return xScale(+datum.ninetyFifthPercentile);
      },
      y2: function (datum: any) {
        return yScale(datum.group) + barHeight / 2;
      },
      x2: function (datum: any) {
        return xScale(+datum.ninetyFifthPercentile);
      },
      class: "ninetyFifth",
    },
  ];

  // draw chart
  useEffect(() => {
    let height = heightRef.current;
    let width = widthRef.current;

    if (
      checkIfValidNUM(height) &&
      checkIfValidNUM(width) &&
      checkIfValidNUM(xMargin.left)
    ) {
      d3.select("#boxAndWhiskerPlot svg").remove();
      d3.select(".tooltip").remove();

      if (!isMobile) {
        var tooltip = d3
          .select("#boxAndWhiskerPlot")
          .append("div")
          .attr("class", "tooltip")
          .style("opacity", 0);
      }

      let svg = d3
        .select("#boxAndWhiskerPlot")
        .append("svg")
        .attr("width", width + xMargin.left + xMargin.right)
        .attr("height", height + yMargin.top + yMargin.bottom)
        .attr("aria-describedby", "chartDesc")
        .append("g")
        .attr(
          "transform",
          "translate(" + xMargin.left + "," + yMargin.top + ")"
        );

      //x-axis
      svg
        .append("g")
        .attr("transform", "translate(0, 0)")
        .style("text-anchor", "middle")
        .attr("class", "xAxisTop")
        .call(d3.axisTop(xScale))
        .attr("transform", "translate(0,5)")
        .selectAll("text")
        .attr("class", "xlabel")
        .attr("transform", "translate(5,0)rotate(0)")
        .style("text-anchor", "end");

      svg
        .append("g")
        .attr("transform", "translate(0," + height + ")")
        .style("text-anchor", "middle")
        .call(d3.axisBottom(xScale))
        .attr("class", "xAxisBottom")
        .selectAll("text")
        .attr("class", "xlabel")
        .attr("transform", "translate(5,0)rotate(0)")
        .style("text-anchor", "end");

      svg
        .append("text")
        .attr("y", -25)
        .attr("x", -4)
        .attr("class", "xTitle")
        .style("text-anchor", "start")
        .attr("transform", "translate(0, 0)")
        .attr("fill", "black")
        .attr("font-weight", "bold")
        .text(
          `${energyUseIntensity.find((x) => x.name === metric)?.value} ${
            energyUseIntensity.find((x) => x.name === metric)?.unit
          }`
        );

      // Add the Y Axis
      svg.append("g").call(d3.axisLeft(yScale)).attr("class", "yAxis");

      d3.selectAll(".yAxis .tick")
        .select("text")
        .attr("class", "ylabel")
        .style("text-anchor", "start")
        .attr("transform", "translate(-" + maxLabelWidth + ",0)");

      d3.selectAll(".yAxis .tick").select("line").attr("visibility", "hidden");

      svg
        .append("text")
        .attr("y", -5)
        .attr("x", -10)
        .attr("class", "yTitle")
        .style("text-anchor", "start")
        .attr("transform", "translate(-" + maxLabelWidth + ",0)")
        .attr("fill", "black")
        .attr("font-weight", "bold")
        .text(readableGroupBy);

      // append a group for the box plot elements
      let g = svg.append("g");

      // Draw the box plot vertical lines
      g.selectAll(".boxGroups")
        .data(data)
        .enter()
        .append("g")
        .attr("class", "boxGroup");

      g.selectAll(".boxGroup")
        .append("rect")
        .attr("fill", "transparent")
        .attr("width", width + xMargin.left + xMargin.right)
        .attr("height", barHeight)
        .attr("y", function (datum: any, i: number) {
          return yScale(datum.group) - barHeight / 2;
        })
        .attr("x", 0)
        .attr("display", function (datum: any) {
          return datum.median === -1 ? "none" : "block";
        })
        .attr("class", "hoverRect");

      g.selectAll(".boxGroup")
        .append("line")
        .attr("class", "line")
        .attr("y1", function (datum: any, i: number) {
          return yScale(datum.group);
        })
        .attr("x1", function (datum: any) {
          return xScale(+datum.fifthPercentile);
        })
        .attr("y2", function (datum: any, i: number) {
          return yScale(datum.group);
        })
        .attr("x2", function (datum: any) {
          return xScale(+datum.ninetyFifthPercentile);
        })
        .attr("stroke", "#000")
        .attr("stroke-width", 1)
        .attr("opacity", function (datum: any) {
          return datum.median === -1 ? 0 : 1;
        })
        .attr("fill", "none");

      // Draw the boxes of the box plot, filled and on top of vertical lines
      g.selectAll(".boxGroup")
        .append("rect")
        .attr("height", barHeight)
        .attr("width", function (datum: any) {
          let width =
            xScale(datum.seventyFifthPercentile) -
            xScale(+datum.twentyFifthPercentile);
          return width;
        })
        .attr("y", function (datum: any, i: number) {
          return yScale(datum.group) - barHeight / 2;
        })
        .attr("x", function (datum: any) {
          return xScale(+datum.twentyFifthPercentile);
        })
        .attr("fill", function (datum: any) {
          return checkIfSummary(datum.group) ? "#d9d7d7" : "#ECECEC";
        })
        .attr("stroke", "#000")
        .attr("opacity", function (datum: any) {
          return datum.median === -1 ? 0 : 1;
        })
        .attr("stroke-width", 1)
        .attr("class", "box");

      g.selectAll(".boxGroup")
        .append("circle")
        .attr("class", "dot")
        .attr("r", barHeight / 4)
        .attr("cy", function (datum: any, i: number) {
          return yScale(datum.group);
        })
        .attr("cx", function (datum: any) {
          return xScale(+datum.mean);
        })
        .attr("opacity", function (datum: any) {
          return datum.median === -1 ? 0 : 1;
        })
        .attr("fill", "#333333");

      for (let i = 0; i < horizontalLineConfigs.length; i++) {
        let lineConfig = horizontalLineConfigs[i];

        // Draw the whiskers at the min for this series
        g.selectAll(".boxGroup")
          .append("line")
          .attr("y1", lineConfig.y1)
          .attr("x1", lineConfig.x1)
          .attr("y2", lineConfig.y2)
          .attr("x2", lineConfig.x2)
          .attr("stroke", function (d: any) {
            return lineConfig.class === "median" && checkIfSummary(d.group)
              ? "#162E51"
              : lineConfig.class === "median" && d.group !== "All"
              ? "#2786AA"
              : "#000";
          })
          .attr("stroke-width", lineConfig.class === "median" ? 3 : 1)
          .attr("fill", "none")
          .attr("opacity", function (datum: any) {
            return datum.median === -1 ? 0 : 1;
          })
          .attr(
            "class",
            lineConfig.class === "fifth"
              ? "fifthWhisker whisker"
              : lineConfig.class === "median"
              ? "medianWhisker whisker"
              : lineConfig.class === "ninetyFifth"
              ? "ninetyFifthWhisker whisker"
              : "whisker"
          );
      }

      d3.selectAll("g.xAxisTop g.tick")
        .append("line")
        .attr("class", "gridline")
        .attr("x1", 0)
        .attr("y1", height)
        .attr("x2", 0)
        .attr("y2", 0)
        .attr("stroke", "#DEDEDE");

      let tooltipWidth: any;
      let tooltipHeight: any;

      d3.selectAll(".boxGroup")
        .on("mouseenter", (event: any) => {
          d3.select(event.target).select(".box").attr("stroke-width", "2px");
          d3.select(event.target).select(".line").attr("stroke-width", "2px");
          d3.select(event.target)
            .select(".whisker")
            .attr("stroke-width", "2px");
          return tooltip.style("opacity", 1);
        })
        .on("mousemove", (event: any, d: any) => {
          if (tooltip && tooltip !== null) {
            tooltipWidth = tooltip.node().getBoundingClientRect().width;
            tooltipHeight = tooltip.node().getBoundingClientRect().height;
          }

          const tooltipOffsetX = -200;
          const tooltipOffsetY = 85;

          let tooltipTop = event.clientY - tooltipHeight - tooltipOffsetY;
          let tooltipLeft = event.clientX - tooltipWidth / 2 + tooltipOffsetX;

          return tooltip
            .style("top", tooltipTop + "px")
            .style("left", tooltipLeft + "px")
            .html(
              "<p class='tooltip-html'><b>" +
                d.group +
                " (" +
                d.rowCount +
                " properties)</b></p><b>" +
                formatNumber(d.fifthPercentile) +
                "</b> 5th | <b>" +
                formatNumber(d.twentyFifthPercentile) +
                "</b> 25th | <b>" +
                formatNumber(d.mean) +
                "</b> Mean | <b class='tooltip-median'>" +
                formatNumber(d.median) +
                "</b> Median | <b>" +
                formatNumber(d.seventyFifthPercentile) +
                "</b> 75th | <b>" +
                formatNumber(d.ninetyFifthPercentile) +
                "</b> 95th"
            );
        })
        .on("mouseleave", (event: any) => {
          d3.select(event.target).select(".box").attr("stroke-width", "1px");
          d3.select(event.target).select(".line").attr("stroke-width", "1px");
          d3.select(event.target)
            .select(".whisker")
            .attr("stroke-width", "1px");
          return tooltip.style("opacity", 0);
        });
    }
    // eslint-disable-next-line
  }, [groupBy, results, containerWidth, isMobile]);

  let svg = d3.select("#boxAndWhiskerPlot");

  // sorting logic
  d3.select("#byGroupName").on("click", () => {
    d3.select("#byGroupName")
      .classed("usa-button--active", true)
      .classed("usa-button--outline", false);
    d3.select("#byMedian")
      .classed("usa-button--active", false)
      .classed("usa-button--outline", true);
    data.sort(function (a: any, b: any) {
      if (checkIfSummary(a.group) !== checkIfSummary(b.group)) {
        return checkIfSummary(a.group) ? -1 : 1;
      } else if (a.group.includes("Before") !== b.group.includes("Before")) {
        return a.group.includes("Before") ? -1 : 1;
      } else if (
        a.group.includes("500,000+") !== b.group.includes("500,000+")
      ) {
        return a.group.includes("500,000+") ? 1 : -1;
      } else if (groupBy === "gfaGroup") {
        return gfaSortOrder.indexOf(a.group) - gfaSortOrder.indexOf(b.group);
      } else {
        if (a.group > b.group) {
          return 1;
        } else if (a.group < b.group) {
          return -1;
        }
      }
      return 0;
    });
    yScale.domain(
      data.map(function (d: any, i: number) {
        return d.group;
      })
    );
    svg
      .selectAll("g .yAxis")
      .transition()
      .duration(500)
      .call(d3.axisLeft(yScale));

    svg
      .selectAll(".box")
      .transition()
      .duration(500)
      .attr("y", function (d: any, i: number) {
        return yScale(d.group) - barHeight / 2;
      });

    svg
      .selectAll(".line")
      .transition()
      .duration(500)
      .attr("y1", function (d: any, i: number) {
        return yScale(d.group);
      })
      .attr("x1", function (d: any) {
        return xScale(+d.fifthPercentile);
      })
      .attr("y2", function (d: any, i: number) {
        return yScale(d.group);
      })
      .attr("x2", function (d: any) {
        return xScale(+d.ninetyFifthPercentile);
      });

    svg
      .selectAll(".dot")
      .transition()
      .duration(500)
      .attr("cy", function (datum: any, i: number) {
        return yScale(datum.group);
      });

    svg
      .selectAll(".hoverRect")
      .transition()
      .duration(500)
      .attr("y", function (datum: any, i: number) {
        return yScale(datum.group) - barHeight / 2;
      });

    let fifthConfig = horizontalLineConfigs[0];
    let medianConfig = horizontalLineConfigs[1];
    let ninetyFifthConfig = horizontalLineConfigs[2];

    svg
      .selectAll(".fifthWhisker")
      .transition()
      .duration(500)
      .attr("y1", fifthConfig.y1)
      .attr("x1", fifthConfig.x1)
      .attr("y2", fifthConfig.y2)
      .attr("x2", fifthConfig.x2);

    svg
      .selectAll(".medianWhisker")
      .transition()
      .duration(500)
      .attr("y1", medianConfig.y1)
      .attr("x1", medianConfig.x1)
      .attr("y2", medianConfig.y2)
      .attr("x2", medianConfig.x2);

    svg
      .selectAll(".ninetyFifthWhisker")
      .transition()
      .duration(500)
      .attr("y1", ninetyFifthConfig.y1)
      .attr("x1", ninetyFifthConfig.x1)
      .attr("y2", ninetyFifthConfig.y2)
      .attr("x2", ninetyFifthConfig.x2);
  });

  d3.select("#byMedian").on("click", function () {
    setIsAscending(!isAscending);
    d3.select("#byMedian")
      .classed("usa-button--active", true)
      .classed("usa-button--outline", false);
    d3.select("#byGroupName")
      .classed("usa-button--active", false)
      .classed("usa-button--outline", true);
    data.sort(function (a: any, b: any) {
      return isAscending
        ? d3.ascending(a.median, b.median)
        : d3.descending(a.median, b.median);
    });
    yScale.domain(
      data.map(function (d: any, i: number) {
        return d.group;
      })
    );
    svg
      .selectAll("g .yAxis")
      .transition()
      .duration(500)
      .call(d3.axisLeft(yScale));

    svg
      .selectAll(".box")
      .transition()
      .duration(500)
      .attr("y", function (d: any, i: number) {
        return yScale(d.group) - barHeight / 2;
      });

    svg
      .selectAll(".line")
      .transition()
      .duration(500)
      .attr("y1", function (d: any, i: number) {
        return yScale(d.group);
      })
      .attr("x1", function (d: any) {
        return xScale(+d.fifthPercentile);
      })
      .attr("y2", function (d: any, i: number) {
        return yScale(d.group);
      })
      .attr("x2", function (d: any) {
        return xScale(+d.ninetyFifthPercentile);
      });

    svg
      .selectAll(".dot")
      .transition()
      .duration(500)
      .attr("cy", function (datum: any, i: number) {
        return yScale(datum.group);
      });

    svg
      .selectAll(".hoverRect")
      .transition()
      .duration(500)
      .attr("y", function (datum: any, i: number) {
        return yScale(datum.group) - barHeight / 2;
      });

    let fifthConfig = horizontalLineConfigs[0];
    let medianConfig = horizontalLineConfigs[1];
    let ninetyFifthConfig = horizontalLineConfigs[2];

    svg
      .selectAll(".fifthWhisker")
      .transition()
      .duration(500)
      .attr("y1", fifthConfig.y1)
      .attr("x1", fifthConfig.x1)
      .attr("y2", fifthConfig.y2)
      .attr("x2", fifthConfig.x2);

    svg
      .selectAll(".medianWhisker")
      .transition()
      .duration(500)
      .attr("y1", medianConfig.y1)
      .attr("x1", medianConfig.x1)
      .attr("y2", medianConfig.y2)
      .attr("x2", medianConfig.x2);

    svg
      .selectAll(".ninetyFifthWhisker")
      .transition()
      .duration(500)
      .attr("y1", ninetyFifthConfig.y1)
      .attr("x1", ninetyFifthConfig.x1)
      .attr("y2", ninetyFifthConfig.y2)
      .attr("x2", ninetyFifthConfig.x2);
  });

  return (
    <div className={styles.BoxAndWhiskerPlot} id="boxAndWhiskerPlotContainer">
      <div className="box-and-whisker-sorting">
        <div className="sorting-container">
          <h5> Sort: </h5>
          <ul
            className={`sort-button-group usa-button-group usa-button-group--segmented ${styles.buttonGroup}`}
          >
            <li className="usa-button-group__item">
              <button
                id="byGroupName"
                type="button"
                className="usa-button usa-button--active sortButton"
              >
                Name
              </button>
            </li>
            <li className="usa-button-group__item">
              <button
                id="byMedian"
                type="button"
                className="usa-button usa-button--outline sortButton"
              >
                Median Value
                {isAscending ? (
                  <SortDescendingIcon scale={0.8} />
                ) : (
                  <SortAscendingIcon scale={0.8} />
                )}
              </button>
            </li>
          </ul>
        </div>
        <div className="legend-container">
          <h5> Legend: </h5>
          <div className={styles.legendBoxWhisker}></div>
        </div>
      </div>

      <p aria-hidden={false} id="chartDesc" className="chartDesc">
        Median {energyUseIntensity.find((x) => x.name === metric)?.value}{" "}
        grouped by {readableGroupBy}.
      </p>
      {!downloading ? (
        <>
        <div
          ref={chartRef}
          id="boxAndWhiskerPlot"
          className={styles.boxAndWhiskerPlotSVG}
        >
          <h2 className={styles.chartTitle}>{chartTitle}</h2>
          <p className={styles.countCaption}>{chartCount}</p>
          <div className={styles.legendContainerDownload}>
            <h5> Legend: </h5>
            <div className={styles.legendBoxWhisker}></div>
          </div>
          <p className={styles.chartCaption}>{caption}</p>
        </div>
        <div className="chart-footer">
        <button
          className="usa-button usa-button--active downloadImgButton"
          onClick={handleExportImage}
        >
          Export Chart as Image
        </button>
        </div>
        </>
      ) : (
        <div className="loading-container">
          <div className="loading-container-inner">
            <p>Downloading your chart...</p>
            <CircularProgress />
          </div>
        </div>
      )}

      <div
        id="fakeBoxAndWhiskerPlot"
        className={styles.boxAndWhiskerPlotSVG}
      ></div>
    </div>
  );
};

export default BoxAndWhiskerPlot;
